import Utils from "../features/utils";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface EmailPreviewController extends ExposeStimulus<typeof EmailPreviewController> {}
export class EmailPreviewController extends StimulusControllerBase {
  connect () {
    const select = this.element.querySelector<HTMLSelectElement>('[data-email="select"]')!
    const preview = this.element.querySelector<HTMLDivElement>('[data-email="preview"]')!

    select.addEventListener('input', async function () {
      const data = await Utils.axios.get(`${window.location.pathname}?template=${select.value}`)

      preview.innerHTML = data.data
    })

    select.dispatchEvent(new Event('input'))
  }
}

export default EmailPreviewController
