import { Controller } from '@hotwired/stimulus'
import { isBottomEdgeVisible, isScrollableY } from '../features/dom_utils'

type CheckboxList = NodeListOf<HTMLInputElement>

export default class SplashscreenController extends Controller {
  static targets = ['scroll']

  connect () {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]') as CheckboxList
    if (isScrollableY(this.scrollTarget)) {
      this.setClickable(checkboxes, false)
      this.setScrollListener(checkboxes)
    }
  }

  setClickable (checkboxes: CheckboxList, clickable: boolean) {
    for (const checkbox of checkboxes) {
      checkbox[clickable ? 'removeAttribute' : 'setAttribute']('disabled', 'disabled')
    }
  }

  setScrollListener (checkboxes: CheckboxList) {
    const target = this.scrollTarget

    const listener = () => {
      if (isBottomEdgeVisible(target, 5)) {
        this.setClickable(checkboxes, true)

        target.removeEventListener('scroll', listener)
        target.removeEventListener('mousewheel', listener)
      }
    }

    target.addEventListener('scroll', listener)
    target.addEventListener('mousewheel', listener)
  }
}
