<template>
  <div class="w-full flex flex-col gap-2 justify-center items-center bg-white p-4 rounded-lg">
    <span class="material-symbols-outlined avv-green-100 text-5xl"> check_circle </span>
    <p class="font-semibold text-base" v-text="localize('title')" />
    <p class="text-sm">
      <span v-text="localize('link') + ' '" />
      <a :href="props.link" class="avv-primary-text" target="_blank" v-text="localize('here')" />
    </p>
    <p v-if="props.large" class="mx-4 text-sm text-center mt-6" v-text="localize('large')" />
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  link?: string
  large?: boolean
}>()

const localize = (key: string) => window.localizeText(`documents.excel.import.queue.${key}`)
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'DocumentsGenerated' })
</script>
