import { Controller } from '@hotwired/stimulus'
import Interval from '../features/interval/interval'
import AutosaveUtils from '../features/autosave/autosave_utils'
import { minutesToMilliseconds } from '../features/time_helpers/time_helpers'

export default class AutosaveController extends Controller {
  static delayInMs = minutesToMilliseconds(5)
  static retryCount = 100
  autosaveInterval!: Interval

  connect() {
    AutosaveUtils.initializeShortcuts()
    this.onAvvStoreInit(this.start.bind(this))
    this.onPageExit(() => AutosaveUtils.beaconSave())
    this.onEditorCreate(AutosaveUtils.initializeState)
  }

  start() {
    if (this.hasAutoSave) {
      const confirmCallback = async (value: boolean) => {
        if (value) {
          AutosaveUtils.setLoadingUI()
          await AutosaveUtils.load()
          AutosaveUtils.setLoadedUI()
          AutosaveUtils.saveTemplate()
        }
      }
      avv_dialog({
        okButtonText: this.localize('ok_button'),
        cancelButtonText: this.localize('no_button', {
          version_id: AvvStore.state.template_version
        }),
        confirmMessage: this.localize('message'),
        confirmTitle: this.localize('title'),
        confirmCallback: (value) => void confirmCallback(value)
      })
    }

    this.autosaveInterval = new Interval(
      async () => {
        // Await the result of the save function, and catch any errors
        try {
          await AutosaveUtils.save()
        } catch (e) {
          // If the error is a 401, we should stop the autosave interval
          if (e.response?.status === 401) {
            this.autosaveInterval.stop()
          } else {
            // Otherwise, we should rethrow the error and let the interval class handle it
            return Promise.reject(e)
          }
        }
      },
      AutosaveController.delayInMs,
      AutosaveController.retryCount
    )
  }

  onAvvStoreInit(callback: () => void) {
    if (window.AvvStore !== undefined) {
      callback()
      return
    } else {
      window.addEventListener('avv-store-created', callback)
    }
  }

  onPageExit(callback: () => void) {
    window.addEventListener('beforeunload', callback)
  }

  onEditorCreate(callback: () => void) {
    window.addEventListener('DOMContentLoaded', () => {
      EditorFactory.mainOptional.ifPresentOrElse(
        callback,
        () => void EditorFactory.onCreate('editor').then(() => callback)
      )
    })
  }

  localize(key: string, args?: Record<string, string>) {
    return localizeText(`autosave.dialog.${key}`, args)
  }

  get hasAutoSave() {
    return AvvStore.state.has_autosave
  }
}
