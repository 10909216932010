import { Controller } from 'stimulus'
import consumer from '../channels/consumer'
import { useUserStore } from '@stores/generic/user.store'
import { getActivePinia } from 'pinia'
import NotificationApi from '@api/NotificationApi'
import { useToast } from '@component-utils/toasts'

export default class NotificationController extends Controller {
  channel: ActionCable.Channel | undefined

  async connect() {
    const store = useUserStore(getActivePinia())
    await store.hydrate({}, ['id', 'display_name'])

    this.channel = consumer.subscriptions.create(
      { channel: 'NotificationChannel', id: store.id },
      {
        received: this.handleReceived.bind(this)
      }
    )
  }

  disconnect() {
    if (this.channel) this.channel.unsubscribe()
  }

  handleReceived(data: {
    id: number
    message: string
    type: 'error' | 'success' | 'alert' | 'notice'
  }) {
    useToast({
      type: data.type === 'alert' ? 'warning' : data.type === 'notice' ? 'default' : data.type,
      message: data.message
    })

    void NotificationApi.destroy({ id: data.id })
  }
}
