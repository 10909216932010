import { Controller } from '@hotwired/stimulus'

import SmartAutomationDialog from '../features/smart_automation/SmartAutomationDialog.vue'
import { AvvNodeGrammar, HtmlParser } from '@avvoka/editor'
import { handleEditorStyles } from '../features/editor/styles'
import { useTemplateVersionStore } from '@stores/generic/templateVersion.store'
import { getActivePinia } from 'pinia'
import { deleteFootnotes, hasText } from '../features/editor/utils'

export default class SmartAutomationController extends Controller {
  open() {
    if (AvvStore.state.editorContentChanged) {
      return window.avv_toast({
        type: 'default',
        message: 'Please save your template before using Smart Automation',
        icon: 'info'
      })
    }

    if (!hasText(EditorFactory.main)) {
      return window.avv_toast({
        type: 'default',
        message: 'Please ensure your template is not empty before using Smart Automation',
        icon: 'info'
      })
    }

    window.avv_dialog({
      vueDialog: SmartAutomationDialog,
      props: {
        templateVersionId: AvvStore.state.template_version_id,
        restrictions: {
          'detect_attributes.open_ai': AvvStore.state.openaiIntegration
        }
      },
      callback: async (
        value: boolean,
        data: { html: string; questions: Backend.Questionnaire.IQuestion[] }
      ) => {
        if (value) {
          await window.generateQuestionnaire()

          if ('qStore' in window) {
            const documentParty = AvvStore.state.start_document_party.party
            const addQuestionParty = (
              question: Backend.Questionnaire.IQuestion
            ) => {
              question['party'] = documentParty
              return question
            }

            window.qStore.commit('SET_QUESTIONS', {
              data: data.questions.map(addQuestionParty)
            })
          }

          const html = deleteFootnotes(data.html, EditorFactory.main)
          const node = HtmlParser.parse(html)
          AvvNodeGrammar.applyDocxGrammar(node)

          EditorFactory.main.load(node)

          handleEditorStyles(
            useTemplateVersionStore(getActivePinia()),
            EditorFactory.main.scroll.node
          )

          setTimeout(() => avv_select_tab('document'), 0)
        }
      }
    })
  }
}
