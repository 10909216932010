import {Application} from "stimulus"
import RevealController from 'stimulus-reveal'
import {registerControllers} from 'stimulus-vite-helpers'

const application = Application.start()

// Register all stimulus controllers when all scripts are loaded
application.register('reveal', RevealController)

// Import all controllers
const controllers = import.meta.glob('../controllers/**/*_controller.ts', {eager: true})
registerControllers(application, controllers)