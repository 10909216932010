import flatpickr from "flatpickr";
import Utils from "../features/utils";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface ReminderController extends ExposeStimulus<typeof ReminderController> {}
export class ReminderController extends StimulusControllerBase {
  static values = { hiddenField: String }

  add (event: PointerEvent) {
    const target = event.currentTarget as HTMLElement

    const dialog = target.closest('.avv_dialog') as HTMLDivElement
    const template = dialog.querySelector('template[data-placeholder]')!
    const html = template.innerHTML.replace(/RECIPIENT_INDEX/g, Date.now().toString())

    target.closest('div')!.insertAdjacentHTML('beforebegin', html)
  }

  delete (event: PointerEvent) {
    const target = event.currentTarget! as HTMLElement

    const parent = target.closest<HTMLDivElement>('[data-recipient]')!
    if (parent.querySelector('[name$="[party_role]"]')) {
      parent.remove()
    } else {
      const deleteField = parent.querySelector<HTMLInputElement>("input[name*='delete']")!
      deleteField.value = '1'
  
      parent.classList.add('hidden')
    }
  }

  async open(event: PointerEvent) {
    const target = event.currentTarget! as HTMLElement

    const reminderAction = target.dataset.modal!
    let reminderId = target.dataset.reminder!
    if (reminderAction === 'delete' && !reminderId) {
      reminderId = Array.from(document.querySelectorAll('input[type="checkbox"][name="reminders[]"]:checked')).map((element: Element) => element.getAttribute('value')).join(',')
    }

    const modal = document.getElementById('reminder-modal')!

    if (reminderId) {
      await this.fillModal(modal, reminderAction, reminderId)
    }
  }

  async fillModal (modal: HTMLElement, type: string, reminder: string) {
    const div = modal.querySelector('div.avv_dialog-wrapper')!

    try {
      const { data: modalHtml } = await Utils.axios.post(`/documents/reminders/${reminder}/modal${window.location.search}`, { type })
      div.innerHTML = modalHtml

      if (type == 'reschedule') {
        flatpickr('#reminder-modal .date-input', {
          dateFormat: 'Y-m-d',
          inline: true,
          altInput: true,
          altFormat: 'j F Y',
          minDate: new Date()
        })
      }

      this.showModal(modal)
    } catch (e) {
      avv_dialog({
        snackStyle: 'alert',
        snackMessage: 'An error occured while loading reminder'
      })
    }
  }

  showModal (modal: HTMLElement) {
    modal.style.display = "flex"
    const DOMEvent = new CustomEvent(`${modal.id}-open`, { detail: {modal}})
    document.dispatchEvent(DOMEvent)
  }

  close () {
    const target = event!.target! as HTMLElement
    const modal = target.closest<HTMLDivElement>(".modal")!
    modal.style.display = "none"
  }

  async sendReminder()  {
    try {
      const params = {[this.hiddenFieldValue]: this.modelId}
      const { data } = await Utils.axios.post<{ noticeMsg: string }>(`/documents/reminders/${this.getAction(this.hiddenFieldValue)}?location=${this.getLocation()}`, params)
      const notice = data.noticeMsg
      avv_dialog({snackMessage: notice, snackStyle: "notice"})
    } catch (error) {
      avv_dialog({snackMessage: (error as { message: string }).message, snackStyle: "error"})
    }
  }

  get modelId() {
    const attribute = 'data-document-model-id-value'
    const input = document.querySelector(`[${attribute}]`)
    return input?.getAttribute(attribute) ?? ''
  }

  getAction(model: string){
    return {
      doc: 'send_reminder_from_index',
      clip: 'send_reminder_from_clip_index'
    }[model.split('_')[0]] ?? 'send_reminder_from_index'
  }

  getLocation () {
    return (window.location.pathname.match(/\/(drive|documents)\/reminders/) ?? [, 'documents'])[1]
  }
}

export default ReminderController
