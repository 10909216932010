import { Controller } from 'stimulus'
import axios from 'axios'
import type { AxiosResponse } from 'axios'

const spinner = `<tr class="infinite-scroll-spinner"><td colspan="10"><div class="infinite-scroll-spinner loader no-size h-[30px] w-[30px] no-m mx-auto my-3 flex justify-center items-center no-w avv-table-text whitespace-nowrap py-3"></div></td></tr>`

export default class extends Controller {
  static targets = ['table', 'entries', 'pagination']

  private loading: boolean | undefined
  private loadingToFindLastPosition: boolean | undefined
  declare readonly tableTarget: HTMLElement
  declare readonly entriesTarget: HTMLElement
  declare readonly paginationTarget: HTMLElement
  private tableWrapper!: HTMLElement

  async modalLoad() {
    this.loading = true
    this.showSpinner()
    let url = this.paginationTarget
      .querySelector('a[rel=next]')
      ?.getAttribute('href')
    if (!url) {
      console.warn('No url specified')
      return
    }
    const params = { version_name: true }
    const response: AxiosResponse<{
      items: []
    }> = await axios.get(url, {
      headers: { accept: 'application/json' },
      params: params
    })

    const data = response.data
    this.hideSpinner()

    if (!data.items.length) {
      this.entriesTarget.classList.add('infinite-scroll-off')
      this.loading = false
      return
    }

    if (this.paginationTarget.classList.contains('mutation-version')) {
      const newUrl = new URL(url, window.location.href)
      const folderId = parseInt(newUrl.searchParams.get('folder_id') ?? '')
      const currentPage = parseInt(newUrl.searchParams.get('page') ?? '')
      sidebarStore.commit('SET_FOLDER_DATA', {
        folderId,
        currentPage,
        folderFiles: data.items,
        folderTemplates: data.items,
        folderCustomClauses: data.items
      })
    }
    this.loading = false
  }

  scrollHandler = () => {
    this.saveScrollPosition(this.tableWrapper)
    if (this.entriesTarget.classList.contains('infinite-scroll-off')) return

    if (
      !this.loading &&
      (this.tableWrapper.scrollHeight <= this.tableWrapper.clientHeight ||
        this.bottomHit())
    )
      this.prepareLoad().catch((e: Error) => {
        window.avv_dialog({ snackMessage: e.message, snackStyle: 'error' })
      })
  }

  async handleScrollingToLastPosition(scrollToId: string) {
    let scrollToElement = document.getElementById(scrollToId)
    if (scrollToElement) this.scrollElementToView(scrollToElement, 500)
    else {
      while (!scrollToElement) {
        this.loadingToFindLastPosition = true
        await this.prepareLoad()
        scrollToElement = document.getElementById(scrollToId)
      }
      this.scrollElementToView(scrollToElement)
      this.loadingToFindLastPosition = false
    }
  }

  scrollElementToView = (scrollToElement: HTMLElement, timeout = 0) => {
    const targetPosition = scrollToElement.getBoundingClientRect().top
    const topOffset = 400
    setTimeout(
      () =>
        this.tableWrapper.scrollTo({
          top: targetPosition - topOffset,
          behavior: 'smooth'
        }),
      timeout
    )
  }

  connect() {
    this.tableWrapper =
      this.tableTarget.closest('.avv-table-ui-wrapper') || this.tableTarget
    const observer = new MutationObserver(() => {
      this.tableWrapper.addEventListener('scroll', this.scrollHandler)
    })
    const tableContainer = this.tableWrapper.closest('.table-container')
    if (tableContainer)
      observer.observe(tableContainer, { childList: true, subtree: true })

    this.tableWrapper.addEventListener('scroll', this.scrollHandler)
    document.addEventListener('DOMContentLoaded', this.scrollHandler)
    window.addEventListener('mainContentChange', () =>{
      this.disconnect() 
      this.connect()
    })
    const scrollToId = new URLSearchParams(window.location.search).get(
      'scroll_to_id'
    )
    if (this.tableWrapper.classList.contains('main-index-table') && scrollToId)
      this.handleScrollingToLastPosition(scrollToId).catch((e: Error) => {
        window.avv_dialog({ snackMessage: e.message, snackStyle: 'error' })
      })

    this.restoreScrollPosition(this.tableWrapper)
  }

  saveScrollPosition(table: HTMLElement) {
    const scrollTop = table.scrollTop
    if (scrollTop) {
      localStorage.setItem(
        'scrollPosition_' + window.location.href,
        String(scrollTop)
      )
    }
  }

  restoreScrollPosition(table: HTMLElement) {
    const referrer = document.referrer
    const lateralTab =
      referrer.includes('archived') !==
        window.location.href.includes('archived') ||
      referrer.includes('_only') !== window.location.href.includes('_only')
    const filteredReferrer = referrer
      .replace(/^https?:\/\/[^\/]+/, '')
      ?.split('?')[0]
    const currentPageUrl = window.location.pathname

    if (
      filteredReferrer &&
      filteredReferrer === currentPageUrl &&
      !lateralTab
    ) {
      var scrollTop = localStorage.getItem(
        'scrollPosition_' + window.location.href
      )
      if (scrollTop) {
        table.scrollTo({ top: +scrollTop, behavior: 'smooth' })
      }
    } else {
      localStorage.removeItem('scrollPosition_' + window.location.href)
    }
  }

  bottomHit() {
    const pixelTolerance = 50
    return (
      this.tableWrapper.scrollTop + this.tableWrapper.clientHeight >=
      this.tableWrapper.scrollHeight - pixelTolerance
    )
  }

  showSpinner() {
    this.entriesTarget.insertAdjacentHTML('beforeend', spinner)
  }

  hideSpinner() {
    this.entriesTarget.querySelector('.infinite-scroll-spinner')?.remove()
  }

  async prepareLoad(): Promise<void> {
    this.loading = true
    let url = this.paginationTarget
      .querySelector('a[rel=next]')
      ?.getAttribute('href')
    if (!url) {
      this.loading = false
      return
    }

    this.showSpinner()
    await this.loadMore(url + '&scrolling=true')
    this.loading = false
  }

  async loadMore(url: string) {
    const params = { version_name: true }
    const response: AxiosResponse<{
      content_html: string
      pagination_html: string
      items:[]
    }> = await axios.get(url, {
      headers: { accept: 'application/json' },
      params: params
    })

    const data = response.data
    this.hideSpinner()
    if (!data.items.length && !data.content_html) {
      this.entriesTarget.classList.add('infinite-scroll-off')
      this.loading = false
      return
    }

    if (this.paginationTarget.classList.contains('mutation-version')) {
      const newUrl = new URL(url, window.location.href)
      const folderId = parseInt(newUrl.searchParams.get('folder_id') as string)
      const currentPage = parseInt(newUrl.searchParams.get('page') as string)
      sidebarStore.commit('SET_FOLDER_DATA', {
        folderId,
        currentPage,
        folderFiles: data.items,
        folderTemplates: data.items,
        folderCustomClauses: data.items
      })
    } else {
      this.entriesTarget.insertAdjacentHTML('beforeend', data.content_html)
      this.paginationTarget.innerHTML = data.pagination_html
    }
    this.loading = false

    if (!this.loadingToFindLastPosition)
      setTimeout(() => this.scrollHandler(), 500)
  }
}
