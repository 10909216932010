<template>
  <div v-if="props.errors" class="w-full flex flex-col gap-1 overflow-y-auto">
    <template v-for="section in renderStructure">
      <div v-if="section.check">
        <b v-text="localize(section.title)" />
        <template v-for="error in section.errors">
          <div v-if="props.errors[error]">
            <span v-text="localize(error)" />
            <ul style="list-style: initial" class="ml-8">
              <li v-for="text in props.errors[error]" :key="text" v-text="text" />
            </ul>
          </div>
        </template>
      </div>
    </template>
  </div>
  <div v-if="props.errors" class="text-sm mt-2" v-text="localize('notice')" />
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'

const props = defineProps<{
  errors: Record<string, boolean | string[]>
}>()

const fileError = computed(() => {
  return props.errors.file_generic || props.errors.file_missing || props.errors.file_large
})
const dataError = computed(() => {
  return props.errors.data_generic || props.errors.data_missing || props.errors.data_invalid
})

const renderStructure = ref([
  {
    title: 'file',
    check: fileError.value,
    errors: ['file_generic', 'file_missing', 'file_large']
  },
  {
    title: 'data',
    check: dataError.value,
    errors: ['data_generic', 'data_missing', 'data_invalid']
  }
])

const localize = (key: string) => window.localizeText(`documents.excel.import.errors.${key}`)
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'Errors' })
</script>
