<template>
  <div class="badge-wrapper" :class="{ 'pointer-events-none': data.freeze }">
    <div class="badge-container">
      <template v-if="sort.key">
        <div class="mr-1 round corners cursor-default badge">
          <button
            class="pl-2 cursor-pointer bg-gray-200 hover:bg-gray-300"
            @click="sortToggle"
          >
            <i
              class="material-icons !text-[24px]"
              :class="{ 'flip-z': sort.asc }"
              >sort</i
            >
            <span class="px-2 text-sm" v-text="sortName" />
          </button>
          <button
            class="px-[0.4rem] cursor-pointer bg-gray-200 hover:bg-gray-300"
            @click="sortRemove"
          >
            <i class="material-icons text-gray-600">clear</i>
          </button>
        </div>
      </template>
      <Badge
        v-for="pair in fixedFilters"
        :key="pair.config.key"
        class="mr-1"
        :filter="pair.filter"
        :config="pair.config"
        @remove="filterRemove(pair.index)"
        @change="(key, values) => filterChange(pair.index, key, values)"
      />
      <Badge
        v-for="pair in floatFilters"
        :key="pair.config.key"
        class="mr-1"
        :filter="pair.filter"
        :config="pair.config"
        @remove="filterRemove(pair.index)"
        @change="(key, values) => filterChange(pair.index, key, values)"
      />
      <template v-if="unusedFilters.length > 0">
        <BadgeBuilder
          v-if="builderOpen"
          class="mr-1"
          :filters="config.filters"
          :available-filters="unusedFilters"
          :active-filters="activeFilters"
          @add="filterAdd"
          @close="builderOpen = false"
        />
        <button
          v-else
          class="round corners badge flex items-center bg-gray-200 hover:bg-gray-300"
          title="Add filter"
          @click="builderOpen = true"
        >
          <div class="px-[0.4rem] flex items-center cursor-pointer">
            <i class="material-icons text-gray-600" v-text="icon" />
          </div>
        </button>
      </template>
      <input
        ref="input"
        type="text"
        :value="data.search"
        class="badge-search ml-2 outline-none border-none"
        style="box-shadow: none;"
        :placeholder="placeholder"
        :aria-label="placeholder"
        @input="searchChange"
        @change="searchChange"
      />
      <button
        v-if="data.search"
        class="avv-danger-color cursor-pointer flex items-center px-1"
        @click="searchRemove"
      >
        <i class="material-icons transform rotate-45">add</i>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import {
  type BadgeData,
  type BadgeConfig,
  type FilterConfigEntry,
  type BadgeEmit
} from './shared'

import Badge from './components/Badge.vue'
import BadgeBuilder from './components/BadgeBuilder.vue'

const props = withDefaults(
  defineProps<{
    data: BadgeData
    config: BadgeConfig
    placeholder?: string
    icon?: string
  }>(),
  {
    placeholder: 'Search ...',
    icon: 'add'
  }
)

const emit = defineEmits<{
  (e: 'change', type: BadgeEmit): void
}>()

const input = ref<HTMLInputElement>()

const builderOpen = ref(false)

const data = computed(() => props.data)
const config = computed(() => props.config)
const placeholder = computed(() => props.placeholder)
const icon = computed(() => props.icon)

const sort = computed(() => data.value.sort)
const sortName = computed(
  () =>
    config.value.sort.find((entry) => entry.key === sort.value.key)?.value ??
    sort.value.key
)

const activeFilters = computed(() => {
  return data.value.filters.map((filter) => filter.key)
})

const fixedFilters = computed(() => {
  return config.value.filters
    .filter(({ type }) => type === 'fixed' || type === 'headless')
    .map((config) => {
      const index = data.value.filters.findIndex(
        (filter) => filter.key === config.key
      )

      return {
        config,
        filter: data.value.filters[index],
        index
      }
    })
})

const floatFilters = computed(() => {
  const skipIndexes = fixedFilters.value
    .filter(({ index }) => index !== -1)
    .map(({ index }) => index)

  return data.value.filters
    .map((filter, index) => ({
      config: config.value.filters.find(
        (entry) => entry.key === filter.key
      ) as FilterConfigEntry,
      filter,
      index
    }))
    .filter(({ index }) => !skipIndexes.includes(index))
})

const unusedFilters = computed(() => {
  const filterNotUsed = ({ type, key }: FilterConfigEntry) => {
    return (
      type !== 'fixed' &&
      type !== 'headless' &&
      (type === 'repeated' ||
        !data.value.filters.find((filter) => filter.key === key))
    )
  }

  return config.value.filters.filter(filterNotUsed)
})

const sortToggle = () => {
  data.value.sort.asc = !data.value.sort.asc
  emit('change', 'sort')
}

const sortRemove = () => {
  data.value.sort.key = ''
  emit('change', 'sort')
}

const filterRemove = (index: number) => {
  data.value.filters.splice(index, 1)
  emit('change', 'filter')
}

const filterAdd = (key: string, values: Array<string | null>) => {
  data.value.filters.push({ key, values })
  emit('change', 'filter')
}

const searchChange = (event: Event) => {
  data.value.search = input.value?.value ?? ''

  if (event.type === 'change') {
    // Emit only on change to avoid too rapid updates
    emit('change', 'search')
  }
}

const searchRemove = () => {
  data.value.search = ''
  emit('change', 'search')
}

const filterChange = (
  index: number,
  key: string,
  values: Array<string | null>
) => {
  if (index === -1) {
    data.value.filters.push({ key, values })
  } else {
    data.value.filters[index] = Object.assign(data.value.filters[index], {
      values
    })
  }

  emit('change', 'filter')
}
</script>
