import {Controller} from "stimulus";

export default class ToggleHiddenController extends Controller {
  static targets = ["select", "hideableElement", "hideableElement2"]
  static values = {
    hideablesClass: String,
    checkboxesClass: String
  }

  declare readonly hideableElementTarget: HTMLElement
  declare readonly hideableElementTargets: HTMLElement[]
  declare readonly hideableElement2Target: HTMLElement
  declare readonly selectTarget: HTMLSelectElement
  declare readonly checkboxesClassValue: string
  declare readonly hideablesClassValue: string

  toggleNextSibling(e: Event) {
    // for 1 hideableElement
    const nxtSibling = (e.target as HTMLElement)?.nextElementSibling
    if (!nxtSibling) return false

    nxtSibling.classList.toggle("hidden")
  }

  hideFromSelect() {
    this.hideableElementTargets.forEach(el => {
      const hideOnTrue = !el.classList.contains("hide-on-false")
      const elHidden = el.classList.contains("hidden")
      if (hideOnTrue) {
        if (elHidden && this.selectTarget.value === 'true' || !elHidden && this.selectTarget.value === 'false') el.classList.toggle("hidden")
      } else if (elHidden && this.selectTarget.value === 'false' || !elHidden && this.selectTarget.value === 'true') el.classList.toggle("hidden")
    })
  }

  hideIfUnchecked(e: Event) {
    // for checkbox(es) and 1 hideableElement
    const triggerChecked = (e.target as HTMLInputElement)?.checked
    const elHidden = this.hideableElementTarget.classList.contains("hidden")

    if (triggerChecked && elHidden) {
      this.hideableElementTarget.classList.remove("hidden")
      return
    }

    if (!triggerChecked && !elHidden && !this.checkboxesClassValue) {
      // when we have only 1 checkbox
      this.hideableElementTarget.classList.add("hidden")
      return
    }

    if (!triggerChecked && !elHidden) {
      // when we have more than 1 checkbox
      const allCheckboxes = Array.from(document.querySelectorAll<HTMLInputElement>(`.${this.checkboxesClassValue}`))
      const someChecked = allCheckboxes.some(item => item.checked)

      if (!someChecked) this.hideableElementTarget.classList.add("hidden")
    }
  }

  hideIfChecked(e: Event) {
    // for checkbox(es) and 1 hideableElement2
    const triggerChecked = (e.target as HTMLInputElement)?.checked
    const elHidden = this.hideableElement2Target.classList.contains("hidden")

    if (triggerChecked && !elHidden) {
      this.hideableElement2Target.classList.add("hidden")
      return
    }

    if (!triggerChecked && elHidden && !this.checkboxesClassValue) {
      // when we have only 1 checkbox
      this.hideableElement2Target.classList.remove("hidden")
      return
    }

    if (!triggerChecked && elHidden) {
      // when we have more than 1 checkbox
      const allCheckboxes = Array.from(document.querySelectorAll<HTMLInputElement>(`.${this.checkboxesClassValue}`))
      const someChecked = allCheckboxes.some(item => item.checked)

      if (!someChecked) this.hideableElement2Target.classList.remove("hidden")
    }
  }

  hideFromChechboxClick(e: Event) {
    // for more than 1 hideableElement
    const targetValue = (e.target as HTMLInputElement)?.value
    const hideables = Array.from(document.getElementsByClassName(this.hideablesClassValue))
    if (!targetValue || !hideables) return false

    const classes = [targetValue, "hidden"]
    hideables.forEach(h => {
      if (classes.every(cl=>h.classList.contains(cl)) || classes.every(cl=>!h.classList.contains(cl))) h.classList.toggle("hidden")
    })
  }

  toggleFromBtnClick() {
    this.hideableElementTarget.classList.toggle("hidden")
  }

}