// scroll to top controller
import { Controller } from "stimulus"

export default class ScrollToTopController extends Controller {
  static targets = ["element", "button"]
  static values = { offset: Number }

  connect() {
    this.elementTarget.addEventListener("scroll", this.handleScroll.bind(this))
  }

  handleScroll() {
    if (this.elementTarget.scrollTop > this.offsetValue) {
      this.buttonTarget.style.display = "inline-flex"
    } else {
      this.buttonTarget.style.display = "none"
    }
  }

  scrollToTop() {
    this.elementTarget.scrollTo(0, 0)
  }
}
