import { StimulusControllerBase } from './base_controller';
import DriveFilesApi from '~/api/DriveFilesApi';

export class TableLoadController extends StimulusControllerBase {

  toggle(e: Event) {
    const button = (e.target as HTMLElement).closest('button');
    if (!button) return;

    const isContentLoaded = button.dataset.loaded === 'true';
    const parentDriveFileId = button.closest('tr')?.dataset.itemId;
    if (!parentDriveFileId) return;

    this.toggleIcon(button);

    // If content is already loaded, just toggle visibility.
    // Otherwise, load content only
    if (isContentLoaded) {
      this.toggleRows(parentDriveFileId);
    } else {
      void this.loadAndInsertContent(button);
    }
  }

  async loadAndInsertContent(button: HTMLElement) {
    const driveFileId = button.dataset.driveFileId;
    if (!driveFileId) return;

    const searchParams = new URLSearchParams(window.location.search);
    try {
        const response = await DriveFilesApi.templatePackDocuments<{content_html: string}>({
            params: { id: driveFileId, query: { type: searchParams.get('type') } }
        });

        this.insertNewRows(button, response.content_html);
        button.dataset.loaded = 'true';
    } catch (error) {
        console.error('Failed to load content:', error);
    }
  }

  insertNewRows(button: HTMLElement, contentHtml: string) {
      const row = button.closest('tr');
      const table = row?.closest('tbody');
      if (!row || !table) {
          console.error('No row or table found');
          return;
      }

      const htmlToParse = `<table>${contentHtml}</table>`;
      const newRows = new DOMParser().parseFromString(htmlToParse, 'text/html').querySelectorAll('tr');
      newRows?.forEach((newRow) => {
          table.insertBefore(newRow, row.nextElementSibling);
      });
  }

  toggleRows(parentDriveFileId: string) {
      const rows = document.querySelectorAll(`[data-parent-drive-file-id="${parentDriveFileId}"][data-item-type="drive-doc"]`);
      rows?.forEach((row) => row.classList.toggle('hidden'));
  }

  toggleIcon(button: HTMLElement) {
      const icon = button.querySelector('i');
      if (icon) icon.classList.toggle('rotate-180');
  }

}
export default TableLoadController
