import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface IManageBrowserController extends ExposeStimulus<typeof IManageBrowserController> {}
export class IManageBrowserController extends StimulusControllerBase {
  static targets = [ "iframe", "modal", "button", "library", "folder", "folderName", "folderNameText", "account", 'save' ] as const
  static values = { url: String }

  _running: boolean = false
  connect() {
    this._running = false
    this.check()
    this.validate()

    window.addEventListener("message", (event) => {
      const data = event.data
      if (data.type === 'cancel') {
        this.remove()
      } else {
        if(this.hasFolderNameTarget) {
          this.folderNameTarget.value = data.data.output_data.loc.name
        }
  
        this.libraryTarget.value = data.data.output_data.loc.database
        this.folderTarget.value = data.data.loc
        this.folderNameTextTarget.innerHTML = data.data.output_data.loc.name
        this.folderNameTextTarget.parentElement.classList.remove("hidden")
      }

      this.modalTarget.classList.add('hidden')

      this.validate()
    }, false);
  }

  check() {
    if(this.hasAccountTarget && this.accountTarget.value === '') {
      this.buttonTarget.disabled = true
    } else if (this.hasAccountTarget) {
      this.buttonTarget.disabled = false
    }
  }

  validate () {
    if (!this.hasSaveTarget) return

    const valid = this.libraryTarget.value && this.folderTarget.value
    this.saveTarget.disabled = !valid
  }

  remove() {
    if(this.hasFolderNameTarget) {
      this.folderNameTarget.value = ''
    }

    this.libraryTarget.value = ''
    this.folderTarget.value = ''
    this.folderNameTextTarget.innerHTML = ''
    this.folderNameTextTarget.parentElement.classList.add("hidden")

    this.validate()
  }

  run(event) {
    event.preventDefault
    let _this = this
    let url = this.urlValue

    if(this.hasAccountTarget) {
      url = url + '?account_id=' + this.accountTarget.value
    }

    fetch(url, {
      method: 'GET',
      dataType: 'script',
      credentials: "include"
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then((data) => {
        _this._running = false
        if (data.error) {
          window.location = data.link
        } else {
          _this.show(data.link)
        }
      })
      .catch(error => {
        console.log(error);
        _this._running = false
      });
  }

  show(link) {
    this.iframeTarget.src = link
    this.modalTarget.classList.remove('hidden')
  }
}

export default IManageBrowserController
