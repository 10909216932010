import SynchronizationsApi from '@api/Integrations/SynchronizationsApi'
import { definePathHelper } from '@js-from-routes/axios'

export type LocationNode = {
  id: string
  name: string
  type: 'file' | 'folder' | string
  metadata?: Record<string, unknown>
  items?: Location
}

export type Location = LocationNode[]

export type Synchronization = {
  location: Location
  sync_minor: boolean
  sync_major: boolean
  sync_executed: boolean
  format_docx: boolean
  format_pdf: boolean
}

export type SynchronizationOptions = {
  sync_minor: boolean
  sync_major: boolean
  sync_executed: boolean
  push_minor: boolean
  push_major: boolean
  push_current: boolean
  format_docx: boolean
  format_pdf: boolean
}

export function stripLocationNode({ id, name, type, metadata }: LocationNode) {
  return {
    id,
    name,
    type,
    metadata
  }
}

export function stripLocation(location: Location) {
  return location.map((node) => stripLocationNode(node))
}

export async function dispatchSynchronizationApi<T = unknown>(
  path: keyof typeof SynchronizationsApi,
  options: Parameters<ReturnType<typeof definePathHelper>>[0]
): Promise<T> | never {
  const response = await SynchronizationsApi[path]<
    { data: T } | { error: string }
  >(options)
  if ('error' in response) {
    throw new Error(response.error ?? 'unknown error')
  } else {
    return response.data
  }
}

const localize = (key: string, args?: Record<string, string>) =>
  window.localizeText(`integrations.dialogs.synchronizations.${key}`, args)

export const PERFORM_OPTIONS_CONFIG = [
  [
    {
      key: 'format_docx',
      hint: localize('options.format_docx.hint')
    },
    {
      key: 'format_pdf'
    }
  ],
  [
    {
      key: 'push_minor',
      hint: localize('options.push_minor.hint')
    },
    {
      key: 'push_major',
      hint: localize('options.push_major.hint')
    },
    {
      key: 'push_current'
    }
  ],
  [
    {
      key: 'sync_minor',
      hint: localize('options.sync_minor.hint')
    },
    {
      key: 'sync_major',
      hint: localize('options.sync_major.hint')
    },
    {
      key: 'sync_executed',
      hint: localize('options.sync_executed.hint')
    }
  ]
] as { key: keyof SynchronizationOptions; hint?: string }[][]
