import { Controller } from "stimulus"

export default class ButtonActionsController extends Controller {
  static targets = ['template', 'container']

  add(event: Event) {
    event.preventDefault()
    this.containerTarget.insertAdjacentElement(
      'beforeend',
      this.templateTarget.content.cloneNode(true).querySelector('div')
    )
  }

  delete(event: Event) {
    event.preventDefault()
    const eTarget = event.target as HTMLElement
    eTarget.closest('.item-to-delete')?.remove()
  }
}