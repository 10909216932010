//typs

export type AIDialogExellReviewData = {
  file: {
    name: string
    file: File | null
    loading: boolean
    link: string
    size: number | null
    errors: Record<string, string[] | boolean> | null
  }
  ai: {
    logs: {
      fileName: string
      body: string
    }[]
    loading: boolean
    firstTime: boolean
    reviewed: boolean
    error: boolean
  }
  documentsGenerated: boolean
}

export type AIDialogFromDocData = {
  file: {
    name: string
    file: File | null
    loading: boolean
    link: string
    size: number | null
    errors: Record<string, string[] | boolean> | null
  }
  ai: {
    logs: {
      fileName: string
      body: string
    }[]
    loading: boolean
    firstTime: boolean
    entriesCreated: boolean
    error: boolean
    entries: object
    enforceAnswers: boolean
  }
}

export type AIDialogFromTextData = {
  text: {
    value: string
    notTouched: boolean
  }
  ai: {
    logs: {
      fileName: string
      body: string
    }[]
    loading: boolean
    firstTime: boolean
    entriesCreated: boolean
    error: boolean
    entries: object
    enforceAnswers: boolean
  }
}

//functions

export const createLog = (evidence: object) => {
  let response = ''
  for (const [key, value] of Object.entries(evidence)) {
    response += `**${key}:**<br>\n${value}<br>\n`
  }
  return response
}

export const createAndSubmitForm = (url: string, inputs: { name: string; value: string }[]) => {
  const form = document.forms[0]

  form.setAttribute('action', url)

  inputs.forEach((input) => {
    const inputElement = document.createElement('input')
    inputElement.setAttribute('type', 'hidden')
    inputElement.setAttribute('name', input.name)
    inputElement.setAttribute('value', input.value)
    form.appendChild(inputElement)
  })
  form.submit()
}
