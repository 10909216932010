import { StimulusControllerBase } from './base_controller'
import {IsHTMLInputElement} from "../features/dom_utils";
import Utils from "../features/utils";

export class PublishingController extends StimulusControllerBase {

    getSelectedItems(selector: string) {
        const selectedItems = Array.from(document.querySelectorAll(selector))
            .filter(
                (checkbox) =>
                    IsHTMLInputElement(checkbox) &&
                    checkbox.checked &&
                    checkbox.value !== 'on'
            )
            .map((checkbox) => IsHTMLInputElement(checkbox) && checkbox.value)
        return selectedItems
    }

    showPublishTemplatesModal() {
        window.sidebarStore.commit('TOGGLE_PUBLISH_MODAL_OPEN')
    }

    async publish() {
        window.sidebarStore.commit('TOGGLE_PUBLISH_MODAL_OPEN')
        const selectedTemplateIds = this.getSelectedItems(
            '.templates-item-check-input'
        )

        const url = '/templates/bulk_action'
        const requestData = {
            bulk_action: 'publish',
            item_ids: selectedTemplateIds
        }
        try {
            const response = await Utils.axios.post(url, requestData)
            if (response.status.toString().startsWith('2')) {
                avv_toast({
                    message: localizeText('templates.publish.notice.all_items_published'),
                    type: 'default'
                })
                setTimeout(() => window.location.reload(), 300)
            } else {
                avv_toast({
                    message: localizeText('templates.publish.notice.not_authorized'),
                    type: 'error'
                })
            }
        } catch (e: unknown) {
            avv_toast({
                message: (e as Error).message,
                type: 'error'
            })
        }
    }
}
export default PublishingController
