<template>
  <textarea
    class="avv-input-tw w-full"
    :class="{
      'h-[200px]': props.height200px
    }"
    @input="input"
  ></textarea>
</template>
<script lang="ts" setup>
import { defineComponent, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    height200px?: boolean
  }>(),
  {
    height200px: false
  }
)

const emit = defineEmits<{
  (e: 'input', event: Event): void
}>()

const input = (e: Event) => {
  emit('input', e)
}
</script>
<script lang="ts">
export default defineComponent({ name: 'TextArea' })
</script>
