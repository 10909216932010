import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class SortableController extends Controller {
  sortable: Sortable

  static values = {
    element: String,
    animation: Number,
    handle: String,
  }

  static targets = ["sortableElement"]

  connect () {
    const element = this.hasElementValue ? document.querySelector(this.elementValue) : this.element
    this.sortable = new Sortable(element, {
      ...this.options
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }


  get options () {
    return {
      animation: this.animationValue || 150,
      handle: this.handleValue || undefined,
    }
  }
}
