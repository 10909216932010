import { IsHTMLElement, IsHTMLInputElement } from '../features/dom_utils'
import Utils from '../features/utils'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import FoldersApi from '@api/FoldersApi'
import LegacyFoldersApi from '@api/LegacyFoldersApi'

const folderIdFromURL = new URLSearchParams(window.location.search).get(
  'folder_id'
)

export interface FolderDeletionController
  extends ExposeStimulus<typeof FolderDeletionController> {}
export class FolderDeletionController extends StimulusControllerBase {
  static values = {
    url: String,
    loopingCount: String,
    mainParentFolderId: String,
    folderId: String,
    childFoldersFilesActions: String,
    childFoldersTemplateTargetsActions: String,
    filesInFolder: Boolean,
    folderType: String,
    folderAsTarget: Boolean
  }

  filesInFolderValueChanged() {
    this.filesInFolderDisplay()
    this.divideModal()
  }

  filesInFolderDisplay() {
    const filesInFolderDiv = document.getElementById('files-in-folder-div')
    if (filesInFolderDiv) {
      filesInFolderDiv.classList.toggle('hidden', !this.filesInFolderValue)
    }
  }

  deleteClick(e: Event) {
    this.stop(e)
    if (IsHTMLElement(e.target)) {
      if (this.urlValue && !this.urlValue.includes('handle')) {
        this.finalDelete(e.target.id)
      } else {
        this.fetchData(e.target.id)
      }
    }
  }

  async finalDelete(id: string) {
    const response = await Utils.axios.post(this.urlValue, this.params(id))
    const { url_path, notice, authorized_msg } = response.data
    const params = new URLSearchParams()
    if (url_path.startsWith('/drive') && folderIdFromURL)
      params.set('folder_id', folderIdFromURL)
    params.set('notice', notice)

    if (authorized_msg === 'false') params.set('authorized', 'false')
    window.location = `${url_path}?${params.toString()}`
  }

  async fetchData(id: string) {
    try {
      const FoldersApiHelper = document.querySelector('.drive-controller')
        ? FoldersApi
        : LegacyFoldersApi
      let response

      if (this.urlValue)
        response = await Utils.axios.post<{ data: { modal_html: string } }>(
          this.urlValue,
          this.params(id)
        )
      else
        response = await FoldersApiHelper.handleFolderDeletion<{
          data: { modal_html: string }
        }>({ data: this.params(id) })
      if (!response) return

      const modalHtml = response.data
        ? response.data.modal_html
        : response.modal_html
      this.showModal(modalHtml)
      this.resolveEmptyFolderSubmit()
    } catch (error: any) {
      avv_dialog({ snackMessage: error.message, snackStyle: 'error' })
    }
  }
  params(id: string) {
    const filesScope = this.filesInFolderValue
    const targetScope = this.folderAsTargetValue
    let output = {
      folder_id: this.folderIdValue || id || '',
      folder_type: this.folderTypeValue || '',
      child_folders_looping: this.loopingCountValue || '',
      main_parent_folder_id: this.mainParentFolderIdValue || '',
      actions_on_files: this.childFoldersFilesActionsValue.concat(
        this.getChildFolderActions(filesScope, 'files-checkbox-div')
      ),
      origin: 'drive'
    }
    if (this.folderTypeValue === 'document') {
      const output_docs = {
        actions_template_targets:
          this.childFoldersTemplateTargetsActionsValue.concat(
            this.getChildFolderActions(targetScope, 'target-checkbox-div')
          )
      }
      output = { ...output, ...output_docs }
    }
    return output
  }

  getChildFolderActions(scope: boolean, divID: string) {
    const checkboxDiv = document.getElementById(divID)
    if (scope && checkboxDiv) {
      const selectedRadio = Array.from(
        checkboxDiv.querySelectorAll('input[type=radio]')
      ).find((el) => IsHTMLInputElement(el) && el.checked)
      if (selectedRadio) {
        return this.calcActionsReturn(divID, selectedRadio)
      }
    } else {
      return ''
    }
  }

  calcActionsReturn(divSelector: string, selectedRadio: any) {
    const newFolderCheckbox = document.getElementById('new_folder_checkbox')
    if (
      (divSelector === 'target-checkbox-div' ||
        this.folderTypeValue === 'template') &&
      IsHTMLInputElement(newFolderCheckbox) &&
      newFolderCheckbox.checked
    ) {
      const foldersDropdownList = document.getElementById(
        'folders-dropdown-list'
      )
      if (!foldersDropdownList) return
      return ` ${this.folderIdValue} ${foldersDropdownList.value}`
    } else {
      return ` ${this.folderIdValue} ${selectedRadio.dataset.folderAction}`
    }
  }

  onCheckboxClick() {
    if (this.folderAsTargetValue) {
      this.resolveDropdownList()
    }
    this.resolveSubmitBtn()
  }

  onContinueBtnClick(e: Event) {
    if (!this.displayFolderAsTarget()) return false
    if (this.loopingCountValue) {
      this.resolveLooping()
    }
    this.deleteClick(e)
  }

  displayFolderAsTarget() {
    // Part 2/2 of dividing the Modal in two separate parts
    const folderAsTarget = document.getElementById('folder-as-target-div')
    if (
      this.filesInFolderValue &&
      this.folderAsTargetValue &&
      folderAsTarget?.classList.contains('hidden')
    ) {
      const filesInFolder = document.getElementById('files-in-folder-div')
      if (!filesInFolder) return false
      filesInFolder.classList.add('hidden')
      folderAsTarget.classList.remove('hidden')
      this.setupBtns()
      return false
    }
    return true
  }

  setupBtns() {
    const submitBtn = document.getElementById('delete-folder-submit-button')
    if (submitBtn) this.toggleDisabled(submitBtn, false)
    if (!this.loopingCountValue || this.loopingCountValue === '0') {
      const submitBtn = document.getElementById('delete-folder-submit-button')
      const hiddenSubmitBtn = document.getElementById('confirm-all-btn')
      if (!submitBtn || !hiddenSubmitBtn) return false
      submitBtn.remove()
      hiddenSubmitBtn.id = 'delete-folder-submit-button'
      hiddenSubmitBtn.classList.remove('hidden')
    }
  }

  resolveSubmitBtn() {
    const deleteFolderSubmitBtn = document.getElementById(
      'delete-folder-submit-button'
    )
    if (IsHTMLElement(deleteFolderSubmitBtn))
      this.toggleDisabled(deleteFolderSubmitBtn, true)
  }

  resolveEmptyFolderSubmit() {
    if (document.getElementById('confirm-deletion-tag')) {
      this.resolveSubmitBtn()
    }
  }

  showModal(html: string) {
    document.getElementById('custom-modal-start')!.innerHTML = html
    document.getElementById('delete-modal')!.classList.remove('hidden')
    document.getElementById('delete-modal')!.style.display = 'flex'
    this.selectSetup()
  }

  stop(e: Event) {
    e.preventDefault()
    return false
  }

  resolveLooping() {
    // Every round, subtract 1 from the total amount of child-loop rounds:
    this.loopingCountValue = (parseInt(this.loopingCountValue) - 1).toString()
  }

  toggleDisabled(element: HTMLElement, condition: boolean) {
    element.toggleAttribute('disabled', !condition)
  }

  selectSetup() {
    const modalComponent = document.getElementById('delete-modal-component')
    if (!modalComponent) return
    const selects = Array.from(modalComponent.getElementsByTagName('select'))
    if (selects.length < 1) return false
    selects.forEach((select) => {
      select.classList.add('py-1', 'pr-8', 'pl-2.5', 'h-8', 'w-full')
      if (select.childElementCount < 1) return false
      select.firstElementChild?.setAttribute('selected', 'true')
    })
  }

  divideModal() {
    // Part 1/2 of dividing the Modal in two separate parts
    if (this.filesInFolderValue && this.folderAsTargetValue) {
      const folderAsTarget = document.getElementById('folder-as-target-div')
      if (!folderAsTarget) return false
      folderAsTarget.classList.add('hidden')
    }
  }

  resolveDropdownList() {
    const newFolderCheckbox = document.getElementById('new_folder_checkbox')
    const foldersDropdownList = document.getElementById('folders-dropdown-list')
    if (
      IsHTMLInputElement(newFolderCheckbox) &&
      newFolderCheckbox.checked &&
      foldersDropdownList
    )
      this.toggleDisabled(foldersDropdownList, newFolderCheckbox.checked)
  }
}

export default FolderDeletionController
