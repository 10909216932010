import { Controller } from 'stimulus'

export default class InputValidationController extends Controller {
  static targets = ["input", "button"]

  verifyInputValue() {
    return !!this.inputTarget.value
  }

  resolveButtonState() {
    this.buttonTarget.toggleAttribute("disabled", !this.verifyInputValue())
  }
}