<template>
  <GlobalDialog
    class="square bg-white !p-4 w-[500px] rounded-md"
  >
    <div class="font-bold text-xl text-center mb-4">Smart Automation</div>
    <hr />
    <div
      class="flex flex-col gap-2 my-3 select-none relative max-h-[60vh] overflow-y-auto"
    >
      <Settings v-model="modules" :restrictions="props.restrictions" @validation="(e) => modulesError = e" />
      <div
        v-if="state.type === 'loading'"
        class="absolute inset-0 gap-2 flex flex-col items-center bg-white justify-center"
      >
        <i
          class="material-symbols-outlined loading text-primary-500 text-6xl"
          aria-hidden="true"
          >progress_activity</i
        >
        <span class="bg-white bg-opacity-80 text-sm" v-text="state.message" />
      </div>
      <div
        v-else-if="state.type === 'error'"
        class="absolute inset-0 gap-2 flex flex-col items-center bg-white justify-center"
      >
        <i
          class="material-symbols-outlined text-red-400 text-6xl"
          aria-hidden="true"
          >error</i
        >
        <span class="bg-white bg-opacity-80 text-sm" v-text="state.message" />
      </div>
      <div
        v-else-if="state.type === 'done'"
        class="absolute inset-0 gap-2 flex flex-col items-center bg-white justify-center"
      >
        <i
          class="material-symbols-outlined text-primary-500 text-6xl"
          aria-hidden="true"
          >done</i
        >
        <span class="bg-white bg-opacity-80 text-sm" v-text="state.message" />
      </div>
    </div>
    <hr />
    <div class="flex justify-end gap-4 mt-4">
      <button
        type="button"
        class="enabled:cursor-pointer max-w-1/3 w-1/3 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm mt-3 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 sm:mt-0"
        :class="{ disabled: state.type !== 'none' }"
        @click="close"
      >
        Cancel
      </button>
      <button
        type="button"
        class="enabled:cursor-pointer max-w-1/3 w-1/3 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 sm:col-start-2"
        :class="{ disabled: state.type !== 'none' || modulesError }"
        :title="modulesError"
        @click="automate"
      >
        Automate
      </button>
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>
import { reactive, ref } from 'vue'

import Settings from './Settings.vue'
import SmartAutomationsApi from '@api/TemplateVersions/SmartAutomationsApi'
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import Utils from './../utils'

type AutomationData = {
  html: string
  questions: Backend.Questionnaire.IQuestion[]
  message: string
}

const modules = ref(new Map())
const modulesError = ref()

const emit = defineEmits<{
  (e: 'callback', result: boolean, data?: AutomationData): void
}>()

const props = defineProps<{
  templateVersionId: number
  restrictions: Record<string, boolean>
}>()

const state = reactive<{
  type: 'none' | 'loading' | 'error' | 'done'
  message?: string
}>({
  type: 'none'
})

const close = () => {
  if (state.type !== 'none') return

  emit('callback', false)
}

const automate = async () => {
  if (state.type !== 'none' || modulesError.value) return

  state.type = 'loading'
  state.message = 'Your document is being automated'

  const { data: response } = await Utils.axios.postForm<
    { error: string } | AutomationData
  >(
    SmartAutomationsApi.create.path({
      template_version_id: props.templateVersionId
    }),
    {
      modules: Object.fromEntries(modules.value.entries())
    },
    {
      validateStatus: () => true
    }
  )

  if (typeof response === 'string' || 'error' in response) {
    state.type = 'error'
    state.message = response.error || 'Unknown error'

    setTimeout(() => {
      state.type = 'none'
    }, 2000)
  } else {
    state.type = 'done'
    state.message = response.message

    setTimeout(() => {
      emit('callback', true, response)
    }, 3000)
  }
}
</script>
<style lang="scss" scoped>
i.loading {
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}
button.disabled {
  opacity: 50%;
  cursor: not-allowed;
  box-shadow: none;
}
</style>
