<template>
  <input type="checkbox" :class="{checked: props.modelValue === true && !intermediate, intermediate: props.intermediate}" @input="onInputChange" :value="props.modelValue" :checked="props.modelValue === true" :readonly="props.readonly" class="avv-button avv-checkbox">
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  modelValue: boolean
  readonly?: boolean
  intermediate?: boolean
}>(), {});

const emit = defineEmits(["update:modelValue"])
const onInputChange = () => {
  if(props.readonly) return;
  emit('update:modelValue', !props.modelValue)
}
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "Checkbox"})
</script>
