import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pwIcon", "pwInput"]

  toggleVisibilityPassword() {
      if (this.pwInputTarget.getAttribute('type') == 'password') {
          this.pwInputTarget.setAttribute('type', 'text')
          this.pwIconTarget.innerText = 'visibility_off'
      } else {
          this.pwInputTarget.setAttribute('type', 'password')
          this.pwIconTarget.innerText = 'visibility'
      }
  }
}