import { Key } from '@avvoka/shared'
import { type ExposeStimulus, StimulusControllerBase } from '../base_controller'

export interface RowFilterController
  extends ExposeStimulus<typeof RowFilterController> {}
export class RowFilterController extends StimulusControllerBase {
  static targets = ['form', 'filterInput', 'clearFilters'] as const
  static values = { headerId: String }

  toggleFilters(event: MouseEvent) {
    event.preventDefault()
    this.clearFiltersTarget.classList.toggle('hidden')
    this.filterInputTargets.forEach((element) =>
      element.classList.toggle('hidden')
    )
  }

  submit(event: KeyboardEvent) {
    const isEnter = event.key === Key.Enter
    if (isEnter) {
      this.filterInputTargets.forEach((element) => {
        const value = element.value.trim()
        if (value.length > 0) {
          const hiddenField = document.createElement('input')
          hiddenField.type = 'hidden'
          hiddenField.name = element.name
          hiddenField.value = value
          this.formTarget.appendChild(hiddenField)
        }
      })
      this.formTarget.submit()
    }
  }
}
export default RowFilterController
