import { Controller } from 'stimulus'
import { goTo } from "../features/topbar/TopbarHelpers";
import ExportApi from '@api/Documents/ExportApi';

export default class PackFinishController extends Controller {

  static values = {
    documentIds: Array
  }
  download(e) {
    this.startLoading(e)

    const data = {
      document_ids: this.documentIdsValue,
      formats: 'document_docx'
    }

    window.avv_download(
      ExportApi.create.path(),
      data
    ).then(() => this.stopLoading(e))
  }

  goToMyDocuments(event: MouseEvent){
    goTo('/documents', event)
  }

  startLoading(e){
    const modal = e.target.closest('.modal')
    const loader = modal.querySelector('.loader')
    loader.classList.remove('hidden')
  }

  stopLoading(e){
    const modal = e.target.closest('.modal')
    const loader = modal.querySelector('.loader')
    loader.classList.add('hidden')
  }
}
