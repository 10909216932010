import {Controller} from "@hotwired/stimulus";

export default class FileViewerController extends Controller {
  zoomableElement!: HTMLElement | null
  zoomPercentage!: HTMLElement | null
  currentZoom = 100

  connect() {
    this.zoomableElement = document.querySelector('#zoomable-element')
    this.zoomPercentage = document.querySelector('.zoom-percentage')
  }

  zoomIn = () => {
    if (!this.zoomableElement || !this.zoomPercentage) return

    this.currentZoom += 10
    this.zoomableElement.style.transform = `scale(${this.currentZoom / 100})`
    this.zoomPercentage.textContent = `${this.currentZoom}%`
  }

  zoomOut = () => {
    if (!this.zoomableElement || !this.zoomPercentage) return

    this.currentZoom -= 10
    this.zoomableElement.style.transform = `scale(${this.currentZoom / 100})`
    this.zoomPercentage.textContent = `${this.currentZoom}%`
  }
}
