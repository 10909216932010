import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface DatasheetImportController extends ExposeStimulus<typeof DatasheetImportController> {}
export class DatasheetImportController extends StimulusControllerBase {
  static targets = ['input'] as const

  submit(event: Event) {
    if (this.inputTarget.checked) {
      event.stopPropagation()
      event.preventDefault()

      window.avv_dialog(
        {
          squareDisplay: true,
          confirmTitle: 'Replace all values',
          confirmMessage: 'You are about to replace all existing values on your Datasheet. If you do not want to complete this action, press the cancel button',
          okButtonText: 'Continue Import',
          cancelButtonText: 'Cancel',
          confirmCallback: (value) => {
            if (value) {
              this.element.submit()
            }
          }
        }
      )
    }
  }
}

export default DatasheetImportController
