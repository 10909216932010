import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface DependentSelectController extends ExposeStimulus<typeof DependentSelectController> {}
export class DependentSelectController extends StimulusControllerBase {
  static values = {
    url: String,
    param: String,
    target: String
  }

  connect() {
    this.element.addEventListener("change", this.handleUpdateTarget.bind(this))
  }

  handleUpdateTarget(event) {
    const urlParams = new URLSearchParams()
    urlParams.set(this.paramValue, event.target.value)
    fetch(`${this.urlValue}?${urlParams.toString()}`).then(response => response.text()).then(data => {
      const selector = document.querySelector(this.targetValue)
      if (selector) selector.outerHTML = data
    })
  }
}

export default DependentSelectController