import checkAll from '@github/check-all'
import { DOMEmit } from '../features/dom_utils'
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";
export interface CheckAllController extends ExposeStimulus<typeof CheckAllController> {}
export class CheckAllController extends StimulusControllerBase {
  static targets = ['all', 'count', 'banner'] as const
  static values = {
    list: String
  }

  globalListener?: (event: Event) => void
  _subscription?: { unsubscribe: () => void }

  connect () {
    if(this.hasAllTarget)
      this.allTarget.addEventListener('change', () =>this._setAttributes())

    this._setAttributes()
    this._subscription = checkAll(this.element)

    if (this.hasBannerTarget && this.hasAllTarget) {
      this.globalListener = (event: Event) => {
        const target = event.target as HTMLInputElement
        if (!(target instanceof Element)) return
        else if (target.hasAttribute('data-check-all')) {
          if (target.checked) {
            this.clearBannerInputs()
            this.showBanner('click')
          } else {
            this.hideBanner()
          }
        } else if (target.hasAttribute('data-check-all-item')) {
          this.emitEvent(this.checked.length)
        }
      }

      document.addEventListener('change', this.globalListener)
    }

    this.emitEvent(this.checked.length)
  }

  disconnect () {
    this._subscription.unsubscribe()
    this._subscription = null

    // Remove global listener
    document.removeEventListener('change', this.globalListener)
  }

  resetBanner () {
    this.allTarget.click()
  }

  clickBanner () {
    this.clearBannerInputs()

    const container: HTMLTableCellElement = this.bannerTarget
    const name = this.checkboxes[0].name
    const ids = container.dataset.ids!.split(',')
    for (const id of ids) {
      if (this.checkboxes.find((checkbox) => checkbox.value == id)) {
        continue
      }

      const input = document.createElement('input')
      input.type = 'checkbox'
      input.name = name
      input.value = id
      input.checked = true
      input.classList.add('hidden')

      container.insertAdjacentElement('beforeend', input)
    }

    this.showBanner('reset')
    this.emitEvent(ids.length)
  }

  clearBannerInputs () {
    const container: HTMLTableCellElement = this.bannerTarget
    for (const input of container.querySelectorAll('input')) {
      input.remove()
    }

    this.emitEvent(this.checked.length)
  }

  emitEvent (count: number) {
    DOMEmit('check-all-change', { count })
  }

  showBanner (form: 'click' | 'reset') {
    this.bannerTarget.classList.remove('hidden')

    const clickElement: HTMLElement = this.bannerTarget.querySelector('[data-banner="click"]')
    const resetElement: HTMLElement = this.bannerTarget.querySelector('[data-banner="reset"]')

    if (form === 'click') {
      clickElement.classList.remove('hidden')
      resetElement.classList.add('hidden')
    } else {
      resetElement.classList.remove('hidden')
      clickElement.classList.add('hidden')
    }
  }

  hideBanner () {
    this.bannerTarget.classList.add('hidden')
    this.clearBannerInputs()
  }

  _setAttributes () {
    if (this.hasAllTarget) {
      this.allTarget.setAttribute('data-check-all', '')
    }
    if (this.hasCountTarget) {
      this.countTarget.setAttribute('data-check-all-count', '')
    }

    this.checkboxes.forEach(e => e.setAttribute('data-check-all-item', ''))
  }

  get checkboxes () {
    const selector = 'input[type="checkbox"]:not([data-check-all]):not([data-skip])'
    const elements = this.element.querySelectorAll<HTMLInputElement>(selector)
    return Array.from(elements)
  }

  get checked () {
    return this.checkboxes.filter(checkbox => checkbox.checked)
  }

  get unchecked () {
    return this.checkboxes.filter(checkbox => !checkbox.checked)
  }
}

export default CheckAllController