import { Controller } from 'stimulus'

export interface StimulusData {
  targets: readonly string[]
  values: {
    readonly [key: string]:
      | typeof String
      | typeof Number
      | typeof Boolean
      | typeof Object
  }
  classes: readonly string[]
}

type ResolveTarget<K extends string> = K extends `${string}Input${string}`
  ? HTMLInputElement
  : K extends `input`
    ? HTMLInputElement
    : K extends `${string}Select${string}`
      ? HTMLSelectElement
      : K extends `select`
        ? HTMLSelectElement
        : K extends `${string}Textarea${string}`
          ? HTMLTextAreaElement
          : K extends `${string}Button${string}`
            ? HTMLButtonElement
            : K extends `button`
              ? HTMLButtonElement
              : K extends `${string}Form${string}`
                ? HTMLFormElement
                : K extends `form`
                  ? HTMLFormElement
                  : K extends `${string}Label${string}`
                    ? HTMLLabelElement
                    : K extends `label`
                      ? HTMLLabelElement
                      : K extends `${string}Option${string}`
                        ? HTMLOptionElement
                        : K extends `${string}Output${string}`
                          ? HTMLOutputElement
                          : K extends `${string}IFrame${string}`
                            ? HTMLIFrameElement
                            : K extends `iframe`
                              ? HTMLIFrameElement
                              : K extends `${string}Progress${string}`
                                ? HTMLProgressElement
                                : HTMLElement

export type ExposeTargetsStimulus<T extends StimulusData['targets']> = {
  [Value in T[number] as `${Value}Target`]: ResolveTarget<Value>
} & {
  [Value in T[number] as `has${Capitalize<Value>}Target`]: boolean
} & {
  [Value in T[number] as `${Value}Targets`]: Array<ResolveTarget<Value>>
}

export type ExposeClassesStimulus<T extends StimulusData['classes']> = {
  [Value in T[number] as `${Value}Class`]: string
} & {
  [Value in T[number] as `has${Capitalize<Value>}Class`]: boolean
} & {
  [Value in T[number] as `${Value}Classes`]: Array<string>
}

export type ExposeValuesStimulus<T extends StimulusData['values']> = {
  [P in keyof T as `${P & string}Value`]: ReturnType<T[P]>
} & {
  [P in keyof T as `has${Capitalize<P & string>}Value`]: boolean
}

export type ExposeStimulus<T extends StimulusData> = ExposeValuesStimulus<
  T['values']
> &
  ExposeTargetsStimulus<T['targets']> &
  ExposeClassesStimulus<T['classes']>

declare module 'stimulus' {
  export interface Controller {
    readonly targets: readonly string[]
    readonly classes: readonly string[]
    readonly values: Record<string, string>
  }
}

export abstract class StimulusControllerBase<
  ElementType extends Element = Element
> extends Controller<ElementType> {
  declare static targets: readonly string[]
  declare static outlets: readonly string[]
  declare static classes: readonly string[]
  declare static values: Record<
    string,
    | StringConstructor
    | BooleanConstructor
    | NumberConstructor
    | ObjectConstructor
  >
}
