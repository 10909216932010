import { Controller } from "stimulus"

import CustomConditionDialog from "../features/custom_reports/CustomConditionDialog.vue"
import {DOMListen, IsHTMLElement, onDomContentLoaded} from "../features/dom_utils";
import type {Component} from "@vue/runtime-core";

const noConditionBuilder = !!document.querySelector('.no-condition-builder')

export default class SelectOptionsController extends Controller {
  static targets = [ 'items', 'templateStatic', 'templateDynamic' ]
  declare readonly itemsTarget: HTMLDivElement
  declare readonly templateStaticTarget: HTMLTemplateElement
  declare readonly templateDynamicTarget: HTMLTemplateElement

  initialize() {
    onDomContentLoaded(() => {
      if (noConditionBuilder) return
      const elements = this.itemsTarget.querySelectorAll('[data-item]')
      elements.forEach((element) => {
        this.initializeConditionBuilder(element as HTMLElement)
        this.updateConditionIcon(element)
      })

      DOMListen('close-condition-builder', (event) => {
        const selectOptionVisibilityBuilder = event.detail.wrapper.closest('.select-option-visiblity-builder')
        if(selectOptionVisibilityBuilder) selectOptionVisibilityBuilder.classList.add('hidden')
      })
  
      const emptyAstStrings = [
        Ast.EMPTY_AST_STRING,
        Ast.EMPTY_AST_STRING.replaceAll(' ', '')
      ]
  
      DOMListen('apply-condition-builder', (event) => {
        const wrapper = event.detail.wrapper.closest('[data-item]')
        if(!wrapper) return;

        const builder = wrapper.querySelector('.select-option-visiblity-builder')
        if (!builder || !builder.nextElementSibling) return;
        const ast = event.detail.astString;

        builder.nextElementSibling.setAttribute('value', emptyAstStrings.includes(ast) ? '' : ast)
  
        this.updateConditionIcon(wrapper)
      })
    })
  }

  updateConditionIcon (wrapper: Element) {
    const builder = wrapper.querySelector('.select-option-visiblity-builder')
    if (!builder) return

    const ast = (builder.nextElementSibling as HTMLInputElement).value
    const icon = wrapper.querySelector('i.select-option-visiblity')

    if(IsHTMLElement(icon)) icon.innerText = ast ? 'visibility_lock' : 'visibility'
  }

  initializeConditionBuilder (wrapper: HTMLElement) {
    const builder = wrapper.querySelector('.select-option-visiblity-builder')
    if (!builder) return

    const ast = (builder.nextElementSibling as HTMLInputElement).value || Ast.EMPTY_AST_STRING
    const app = Vue.createApp(CustomConditionDialog as Component, {
      astString: ast,
      attributes: [],
      values: Ast.traverse(Ast.parse(ast)!).values,
      type: 'attributes'
    })

    app.mount(builder)
  }

  addStatic (event: PointerEvent) {
    this.add(event, this.templateStaticTarget)
  }

  addDynamic (event: PointerEvent) {
    this.add(event, this.templateDynamicTarget)
  }

  add (event: PointerEvent, target: HTMLTemplateElement) {
    event.preventDefault()

    const element = target.content.cloneNode(true)
    this.itemsTarget.appendChild(element)

    const wrapper = this.itemsTarget.lastElementChild
    if (!noConditionBuilder) this.initializeConditionBuilder(wrapper as HTMLElement)
  }

  remove (event: PointerEvent) {
    event.preventDefault()

    const element = event.target as HTMLElement
    const wrapper = element.closest('[data-item]')
    const parent = wrapper?.parentElement

    if(wrapper) wrapper.remove()
    if (noConditionBuilder) this.previewOptions(event, parent as HTMLElement)
  }

  condition (event: PointerEvent) {
    const element = event.target as HTMLElement
    const wrapper = element.closest('[data-item]')
    if (!wrapper || !IsHTMLElement(wrapper)) return
    const builder = wrapper.querySelector('.select-option-visiblity-builder')
    if (!builder) return
    const classes = builder.classList

    if (classes.contains('hidden')) {
      classes.remove('hidden')
    } else {
      classes.add('hidden')
    }
  }

  previewOptions(event: Event, triggerElement: HTMLElement) {
    const triggerEl = triggerElement || event.target
    if (!(triggerEl instanceof HTMLElement)) throw new Error('triggerEl not instanceof HTMLElement')

    const selectableContainer = triggerEl.closest('.selectable-content-container')
    if (selectableContainer == null) throw new Error('Unable to find `.selectable-content-container`')

    const labelInputs = selectableContainer.querySelectorAll<HTMLInputElement>('.metadata-select-label')
    const previewElement = selectableContainer.querySelector<HTMLInputElement>('.metadata-select-preview')
    if (previewElement == null) throw new Error('Unable to find `.metadata-select-preview`')

    const values = Array.from(labelInputs).map(input => input.value)
    previewElement.value = values.join(', ')
  }
}
