<template>
  <GlobalDialog class="flex w-1/3 flex-col dialog">
    <div class="flex justify-between items-center mb-2">
      <h1 class="text-black font-bold" v-text="localize('title')"/>
      <i class="material-icons transform rotate-45 text-3xl cursor-pointer" :title="localize('close')" @click="close">add</i>
    </div>
    <hr/>
    <input v-model="filter" :placeholder="localize('filter')" class="avv-input">
    <div class="flex flex-col h-[60vh] overflow-y-scroll">
      <div class="flex justify-between p-2 bg-primary-300 avv-primary-bg-text cursor-pointer" @click="toggleProfiles">
        <h3 v-text="localize('profiles', { count: filteredProfiles.length })"/>
        <i class="material-icons" aria-hidden="true" v-text="showProfiles ? 'visibility' : 'visibility_off'"/>
      </div>
      <template v-if="showProfiles">
        <div v-for="profile in filteredProfiles" :key="profile.id" class="flex items-center pl-2 p-1 gap-1 hover:bg-gray-200 show-buttons-on-hover">
          <div class="w-full my-1" v-text="`${profile.name} (#${profile.id})`"/>
          <button v-if="hasTemplates" class="avv-button" :title="localize('actions.move_profile')" @click="commit('move', 'profile_id', profile.id)">
            <i class="material-icons" aria-hidden="true">keyboard_tab</i>
          </button>
          <button v-if="hasTemplates" class="avv-button" :title="localize('actions.copy')" @click="commit('copy', 'profile_id', profile.id)">
            <i class="material-icons" aria-hidden="true">file_copy</i>
          </button>
          <button v-if="!hasTemplates" class="avv-button" :title="localize('actions.add')" @click="commit('add', 'profile_id', profile.id)">
            <i class="material-icons" aria-hidden="true">add</i>
          </button>
        </div>
      </template>
      <div class="flex justify-between p-2 bg-primary-300 avv-primary-bg-text cursor-pointer" @click="toggleOrganisations">
        <h3 v-text="localize('organisations', { count: filteredOrganisations.length })"/>
        <i class="material-icons" aria-hidden="true" v-text="showOrganisations ? 'visibility' : 'visibility_off'"/>
      </div>
      <template v-if="showOrganisations">
        <div v-for="organisation in filteredOrganisations" :key="organisation.id" class="flex items-center pl-2 p-1 gap-1 hover:bg-gray-200 show-buttons-on-hover">
          <div class="w-full my-1" v-text="`${organisation.name} (#${organisation.id})`"/>
          <button v-if="hasTemplates" class="avv-button" :title="localize('actions.move_organisation')" @click="commit('move', 'organisation_id', organisation.id)">
            <i class="material-icons" aria-hidden="true">keyboard_tab</i>
          </button>
          <button v-if="hasTemplates" class="avv-button" :title="localize('actions.copy')" @click="commit('copy', 'organisation_id', organisation.id)">
            <i class="material-icons" aria-hidden="true">file_copy</i>
          </button>
          <button v-if="!hasTemplates" class="avv-button" :title="localize('actions.add')" @click="commit('add', 'organisation_id', organisation.id)">
            <i class="material-icons" aria-hidden="true">add</i>
          </button>
        </div>
      </template>
    </div>
  </GlobalDialog>
</template>
<script lang="ts">
  import { defineComponent, toRefs, computed, ref } from "vue";

  import GlobalDialog from '../dialogs/GlobalDialog.vue'
  import Utils from '../utils'

  type Profile = {
    id: number,
    name: string
  }

  type Organisation = {
    id: number,
    name: string
  }

  export default defineComponent({
    name: 'AdminTemplatesDialog',
    components: { GlobalDialog },
    props: [ 'profiles', 'organisations', 'templates' ],
    emits: [ 'callback' ],
    setup(props, ctx) {
      const { profiles, organisations, templates } = toRefs(props)
      const filter = ref('')

      const showOrganisations = ref(true)
      const showProfiles = ref(true)

      const toggleOrganisations = () => showOrganisations.value = !showOrganisations.value
      const toggleProfiles = () => showProfiles.value = !showProfiles.value

      const localize = (key: string, args?: any) => window.localizeText(`admin.templates_dialog.${key}`, args)

      const close = () => {
        ctx.emit('callback')
      }

      const commit = (operation: string, paramName: string, paramValue: string) => {
        if (operation === 'add') {
          const urlParams = new URLSearchParams({ [paramName]: paramValue })

          window.location.href = `/templates/new?${urlParams.toString()}`
        } else {
          const createInput = (form: HTMLFormElement, name: string, value: string) => {
            const input = document.createElement('input')
  
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', name)
            input.setAttribute('value', value)
            
            form.appendChild(input)
          }

          const form = document.createElement('form') as HTMLFormElement
          form.action = '/admin/templates/apply'
          form.method = 'post'
          form.acceptCharset = 'UTF-8'

          createInput(form, 'authenticity_token', Utils.CSRFToken)
          createInput(form, 'operation', operation)
          createInput(form, paramName, paramValue)

          for (const template of templates.value) {
            createInput(form, 'templates[]', template)
          }

          document.body.appendChild(form)

          form.submit()
        }
      }

      const filteredProfiles = computed(() => {
        if (filter.value) {
          const filterText = filter.value.toLowerCase()
          return profiles.value.filter((profile: Profile) => profile.name.toLowerCase().includes(filterText))
        } else {
          return profiles.value
        }
      })
      
      const filteredOrganisations = computed(() => {
        if (filter.value) {
          const filterText = filter.value.toLowerCase()
          return organisations.value.filter((organisation: Organisation) => organisation.name.toLowerCase().includes(filterText))
        } else {
          return organisations.value
        }
      })

      const hasTemplates = computed(() => {
        return templates.value.length > 0
      })

      return {
        close,
        localize,
        filter,
        filteredProfiles,
        filteredOrganisations,
        hasTemplates,
        commit,
        showOrganisations,
        showProfiles,
        toggleOrganisations,
        toggleProfiles
      }
    }
  })
</script>
<style lang="scss" scoped>
  .show-buttons-on-hover {
    button {
      display: none;
    }

    &:hover {
      button {
        display: inline-flex;
      }
    }
  }

  .avv-button {
    padding: 3px;

    min-height: initial;
    min-width: initial;

    i {
      margin: 0
    }
  }
</style>