import { stopAndPrevent } from '../../features/_abstract/utils/event'
import { type ExposeStimulus, StimulusControllerBase } from '../base_controller'

export interface HeaderDependenciesController
  extends ExposeStimulus<typeof HeaderDependenciesController> {}

export class HeaderDependenciesController extends StimulusControllerBase {
  static targets = ['container', 'template']

  connect() {
    this.element.addEventListener('click', (event) => {
      const dependency = (event.target as HTMLElement | null)?.closest(
        '[data-remove-dependency]'
      )
      if (dependency) dependency.parentElement?.remove()
    })
  }

  add(e: PointerEvent) {
    stopAndPrevent(e)

    this.containerTarget.insertAdjacentElement(
      'beforeend',
      this.templateTarget.content.cloneNode(true).querySelector('div')
    )
  }
}

export default HeaderDependenciesController
