<template>
  <div class="round corners badge">
    <SelectSegment
      :value="filterKey"
      :segment="{
        options: filterKeyOptions,
        placeholder: 'Select filter',
        search: true
      }"
      :setup="filterKeyOpen"
      @change="filterKeyChange"
    />
    <template v-for="(segment, index) of filterSegments">
      <template v-if="isInteractiveSegment(segment)">
        <component
          :is="getSegmentComponent(segment.type)"
          :key="`${segment.type}-${index}`"
          :segment="segment"
          :value="segmentValue(segment.index)"
          :edit="segmentsEditable"
          :setup="segmentOpen(segment.index)"
          @change="(val: string) => segmentChange(segment.index, val)"
          @open="segmentSelect(segment.index)"
          @close="segmentUnselect(segment.index)"
        />
      </template>
      <template v-else>
        <component
          :is="getSegmentComponent(segment.type)"
          :key="`${segment.type}-${index}`"
          :value="segment.default"
        />
      </template>
    </template>
    <button
      class="px-[0.4rem] cursor-pointer bg-gray-200 hover:bg-gray-300"
      @click="builderClose"
    >
      <i class="material-icons text-gray-600">clear</i>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, watchEffect } from 'vue'
import {
  type FilterConfigEntry,
  type BadgeBuilderData,
  isBadgeBuilderItemSet,
  isInteractiveSegment,
  getSegmentComponent
} from '../shared'

import SelectSegment from './segments/SelectSegment.vue'

const props = defineProps<{
  filters: Array<FilterConfigEntry>
  availableFilters: Array<FilterConfigEntry>
  activeFilters: Array<string>
}>()

const emit = defineEmits<{
  (e: 'add', key: string, value: Array<string | null>): void
  (e: 'close'): void
}>()

const data = reactive<BadgeBuilderData>({
  key: { val: null, set: false },
  segments: [],
  current: null
})

const builderClose = () => {
  emit('close')
}

// All available filters
const activeFilters = computed(() => props.activeFilters)

const allFilters = computed(() => props.filters)
const availableFilters = computed(() => props.availableFilters)

// Filter key
const filterKey = computed(() => data.key.val)
const filterKeyOpen = computed(() => !data.key.set)
const filterKeyOptions = computed(() => {
  return availableFilters.value.map(({ key, value, require }) => {
    const requiredFilter = allFilters.value.find(({ key }) => key === require)

    return {
      key,
      value,
      disabled: requiredFilter && !activeFilters.value.includes(require),
      title: requiredFilter
        ? `This filter is dependent on ${requiredFilter.value} filter`
        : undefined
    }
  })
})

// Filter config
const findConfig = (key: string) => {
  const finder = ({ key: _key }: FilterConfigEntry) => _key === key

  return availableFilters.value.find(finder) as FilterConfigEntry
}

// Filter segments
const filterSegments = computed(() => {
  if (isBadgeBuilderItemSet(data.key)) {
    return findConfig(data.key.val).segments
  } else {
    return []
  }
})

// Filter key change
const filterKeyChange = (val: string) => {
  const config = findConfig(val)

  data.current = null
  data.segments = config.segments
    .filter(isInteractiveSegment)
    .map((segment) => ({
      val: segment.default,
      set: false
    }))

  data.key = { val, set: true }
}

// Returns whether segment is not set yet & should be open
const segmentOpen = (index: number) => {
  return (
    isBadgeBuilderItemSet(data.key) &&
    (data.current === index ||
      (data.current === null &&
        !isBadgeBuilderItemSet(data.segments[index]) &&
        data.segments.slice(0, index).every(isBadgeBuilderItemSet)))
  )
}

const segmentsEditable = computed(() => isBadgeBuilderItemSet(data.key))

// Return value of interactive segment at index
const segmentValue = (index: number) => {
  return data.segments[index].val
}

// Filter segment change
const segmentChange = (index: number, val: string) => {
  data.segments[index] = { val, set: true }
}

// Manually opening and closing certain segment
const segmentSelect = (index: number) => {
  data.current = index
}

const segmentUnselect = (index: number) => {
  if (data.current === index) {
    data.current = null
  }
}

watchEffect(() => {
  if (
    isBadgeBuilderItemSet(data.key) &&
    data.segments.every(isBadgeBuilderItemSet)
  ) {
    const key = data.key.val
    const val = data.segments.map(({ val }) => val)

    emit('close')
    emit('add', key, val)
  }
})
</script>
