<template>
  <div class="flex justify-between px-3 py-0.5">
    <p v-text="props.file_name" />
    <button
      type="button"
      :disable="props.disable"
      :class="{
        'cursor-not-allowed opacity-50 cursor-custom': props.disable
      }"
      @click="emit('close')"
    >
      <span class="material-symbols-outlined">close</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    file_name?: string
    disable?: boolean
  }>(),
  {
    disable: false
  }
)

const emit = defineEmits(['close'])
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'File' })
</script>
