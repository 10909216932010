import { type ExposeStimulus, StimulusControllerBase } from './base_controller'

export interface ToggableController
  extends ExposeStimulus<typeof ToggableController, HTMLInputElement> {}
export default class ToggableController extends StimulusControllerBase {
  static targets = ['item', 'input'] as const
  static classes = ['hide']

  connect() {
    this.toggle()
  }

  toggle() {
    this.setState(this.inputTarget.checked)
  }

  show(event) {
    event.preventDefault()
    this.setState(true)
  }

  hide(event) {
    event.preventDefault()
    this.setState(false)
  }

  setState(state) {
    if (state) {
      this.itemTargets.forEach((element) =>
        element.classList.remove(this.hideClass)
      )
    } else {
      this.itemTargets.forEach((element) =>
        element.classList.add(this.hideClass)
      )
    }
  }
}
