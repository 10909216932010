import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface ImportController extends ExposeStimulus<typeof ImportController> {}
export class ImportController extends StimulusControllerBase {

  static targets = [ 'label', 'form' ] as const

  originalText: string | null = null
  connect(){
    this.originalText = this.labelTarget.textContent
  }

  onChange(e){
    this.labelTarget.textContent = e.target.files[0].name ?? this.originalText
    this.formTarget.submit()
  }
}

export default ImportController