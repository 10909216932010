import axios from 'axios'
import { StimulusControllerBase, type ExposeStimulus } from './base_controller'

const isDriveController = !!document.querySelector('.drive-controller')
interface LabelParams {
  search?: string
  name: string
  description: string
  access: 'user' | 'profile' | 'organisation'
  profile_id?: string
  color: string
  related_to: 'documents' | 'templates'
  id: string
}

export interface LabelsController
  extends ExposeStimulus<typeof LabelsController> {}

export class LabelsController extends StimulusControllerBase {
  static targets = ['colorPalette', 'colorInput', 'colorRectangle']

  toggleColorPalette() {
    this.colorPaletteTarget.classList.toggle('hidden')
    this.colorPaletteTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }

  chooseColor(event: Event) {
    const chosenColor = (event.target as HTMLElement).classList[0]
    this.colorRectangleTarget.className = `w-20 h-6 color-div ${chosenColor}`
    this.colorRectangleTarget.style.backgroundColor = ''
    this.colorPaletteTarget.classList.add('hidden')
    this.colorInputTarget.value = chosenColor
  }

  add() {
    const table = document.querySelector<HTMLTableElement>('table')
    const content = document.getElementById('row-template')?.innerHTML
    table.tBodies[0].insertAdjacentHTML('beforeend', content as string)
  }

  resetInputs(row: HTMLTableRowElement) {
    const inputs = row.querySelectorAll<HTMLInputElement>('input, textarea')
    inputs.forEach((input) => (input.value = ''))
  }

  toggleRow(event: MouseEvent) {
    const row = this.getParentRow(event.target as HTMLElement)
    row?.nextElementSibling?.classList.toggle('hidden')
  }

  getParentRow(el: HTMLElement): HTMLTableRowElement | null {
    if (el.tagName.toLowerCase() === 'tr') return el as HTMLTableRowElement
    if (el.tagName.toLowerCase() === 'table') return null
    else return this.getParentRow(el.parentElement as HTMLElement)
  }

  save(e: MouseEvent) {
    const row: HTMLTableRowElement = this.getParentRow(e.target as HTMLElement)
    const params = this.getRowParams(row)
    const isNew = row.classList.contains('new-label-row')
    void this.ajax(isNew ? 'create' : 'update', params)
  }

  async delete(e: MouseEvent) {
    const row: HTMLTableRowElement = this.getParentRow(e.target as HTMLElement)
    const params = this.getRowParams(row)
    if (!row.classList.contains('new-label-row')) {
      await this.ajax('delete', params)
    }
    row.nextElementSibling?.remove()
    row.remove()
  }

  getRowParams(row: HTMLTableRowElement): LabelParams {
    const color = (row.querySelector('input[name="color"]') as HTMLInputElement)
      ?.value
    const name = (row.querySelector('input[name="name"]') as HTMLInputElement)
      ?.value
    const accessSelect = row.querySelector(
      'select[name="access"]'
    ) as HTMLInputElement
    let access = accessSelect?.value
    let profile_id = ''
    if (access.includes('profile')) {
      const splited = access.split('-')
      profile_id = splited[1]
      access = splited[0]
    }
    const description = row.nextElementSibling?.querySelector('textarea')
      ?.value as string
    const related_to = window.location.href.includes('documents')
      ? 'documents'
      : 'templates'
    const id = row.id.replace('label_', '')

    return {
      color,
      name,
      access,
      description,
      profile_id,
      related_to,
      id,
      search: ''
    }
  }

  async ajax(type: 'create' | 'update' | 'delete', params: LabelParams) {
    const url = `${
      type === 'create' ? `/labels/create` : `/labels/${params.id}/${type}`
    }.json`
    const method = {
      create: 'post',
      update: 'put',
      delete: 'delete'
    } as const
    const { status, data } = await axios[method[type]](url, params, {
      validateStatus: () => true
    })
    const success = status === 200
    const snackMessage = success
      ? `Label was ${type}d successfully`
      : data.error
    const snackStyle = success ? 'notice' : 'error'
    avv_dialog({ snackMessage, snackStyle })
  }

  async filterLabels(e: Event) {
    if (!isDriveController) return

    const labelDialogDiv = (e.target as HTMLElement).closest(
      '.label-dialog-div'
    )
    const labelList = labelDialogDiv?.querySelector('.label-list')
    const params = {
      search_input: (e.target as HTMLInputElement).value.toLowerCase()
    }

    const url = `/drive/${
      (e.target as HTMLElement)?.dataset.documentId || ''
    }/labels/filter`
    const response = await axios.get<{
      data: { label_list: string }
      status: number
    }>(url, { params })
    const { data, status } = response

    const success = status === 200
    if (success && labelList) labelList.innerHTML = data.label_list
  }
}

export default LabelsController
