import SortableController from '../sortable_controller'

export default class HeaderListController extends SortableController {
  static targets = ['inputPosition']

  connect() {
    super.connect()
    this.updateInputPositions()
  }

  updateInputPositions() {
    this.inputPositionTargets.forEach((node, index) => (node.value = index))
  }

  handleInputTypeChange(event: Event) {
    if (!event.target || !(event.target instanceof HTMLSelectElement)) {
      return console.warn(
        `${event.target} either doesnt exist or is not an Select element`
      )
    }
    const inputType = event.target.value

    const nextSibling = event.target.parentElement?.nextElementSibling
    if (!nextSibling) {
      return console.warn(`Unable to find nextElementSibling`)
    }

    const inputValuesEl = nextSibling.querySelector('input.input-list-values')
    const inputFormatEl = nextSibling.querySelector('select.input-date-format')
    const inputSourceEl = nextSibling.querySelector(
      'select.input-list-source'
    ) as HTMLSelectElement
    const inputHeaderEl = nextSibling.querySelector(
      'select.input-datasheet-header'
    )
    const inputDatashEl = nextSibling.querySelector(
      'select.input-datasheet-name'
    ) as HTMLSelectElement

    const headerDependenciesEl = nextSibling.querySelector(
      '.input-datasheet-header-dependencies'
    )

    for (const el of [
      inputValuesEl,
      inputFormatEl,
      inputSourceEl,
      inputHeaderEl,
      inputDatashEl,
      headerDependenciesEl
    ]) {
      if (!el) return console.warn('Missing input for nextSibling element')
      el.classList.add('hidden')
    }

    if (inputType == 'select' || inputType == 'open_select') {
      inputSourceEl?.classList.remove('hidden')
      if (inputSourceEl?.value === 'datasheet') {
        inputHeaderEl?.classList.remove('hidden')
        inputDatashEl?.classList.remove('hidden')
        headerDependenciesEl?.classList.remove('hidden')
        this.selectDatasheet(event.target, inputDatashEl?.value)
      } else {
        inputValuesEl?.classList.remove('hidden')
      }
    } else if (inputType === 'radio') {
      inputValuesEl?.classList.remove('hidden')
    } else if (inputType === 'date') {
      inputFormatEl?.classList.remove('hidden')
    }
  }

  handleDatasheetNameChange(event: Event) {
    if (!event.target || !(event.target instanceof HTMLSelectElement)) return
    const datasheetName = event.target?.value
    this.selectDatasheet(event.target, datasheetName)
  }

  selectDatasheet(caller: HTMLElement, sheetName: string) {
    const parentEl = caller.closest('tr')

    if (!parentEl) {
      return console.warn('Caller is not inside any tr element')
    }

    const options = [
      ...Array.from(
        parentEl.querySelectorAll<HTMLOptionElement>('option[data-datasheet]')
      ),
      ...Array.from(
        parentEl
          .querySelector<HTMLTemplateElement>('template')
          ?.content.querySelectorAll<HTMLOptionElement>(
            'option[data-datasheet]'
          ) ?? []
      )
    ]

    let optionSet = false

    options.forEach((option) => {
      if (!option.dataset.datasheet) {
      } else if (option.dataset.datasheet == sheetName) {
        option.classList.remove('hidden')
        if (!optionSet) {
          const inputDatashEl = parentEl.querySelector(
            'select.input-datasheet-header'
          )
          if (!inputDatashEl || !(inputDatashEl instanceof HTMLSelectElement)) {
            return console.warn('Couldnt find datasheet select input')
          }
          inputDatashEl.value = option.value
          optionSet = true
        }
      } else {
        option.classList.add('hidden')
      }
    })

    const dependenciesReferences = parentEl.querySelectorAll<HTMLOptionElement>(
      '[name$="[datasheet_header_dependencies][][reference_id]"]'
    )
    for (const dependencyReference of dependenciesReferences)
      dependencyReference.value = ''
  }

  handleListSourceChange(event: Event) {
    if (!event.target || !(event.target instanceof HTMLSelectElement)) return
    const inputType = event.target.value

    const parentEl = event.target.parentElement?.parentElement
    const inputValuesEl = parentEl?.querySelector<HTMLInputElement>(
      'input.input-list-values'
    )
    const inputHeaderEl = parentEl?.querySelector<HTMLSelectElement>(
      'select.input-datasheet-header'
    )
    const inputDatashEl = parentEl?.querySelector<HTMLSelectElement>(
      'select.input-datasheet-name'
    )

    const headerDependenciesEl = parentEl?.querySelector(
      '.input-datasheet-header-dependencies'
    )

    if (
      !inputValuesEl ||
      !inputHeaderEl ||
      !inputDatashEl ||
      !headerDependenciesEl
    )
      return

    if (inputType === 'datasheet') {
      inputValuesEl.classList.add('hidden')
      inputHeaderEl.classList.remove('hidden')
      inputDatashEl.classList.remove('hidden')
      headerDependenciesEl.classList.remove('hidden')
      this.selectDatasheet(event.target, inputDatashEl.value)
    } else {
      inputValuesEl.classList.remove('hidden')
      inputHeaderEl.classList.add('hidden')
      inputDatashEl.classList.add('hidden')
      headerDependenciesEl.classList.add('hidden')
    }
  }
}
