import { Controller } from '@hotwired/stimulus'
import { IsHTMLElement } from '../features/dom_utils'

export default class AccessibilityController extends Controller {
  onKeyDown(e: KeyboardEvent) {
    const select = this.getSelectFromEvent(e)
    if (!select) {
      console.error('No select found')
      return
    }
    this.ensureTabIndex(select)
    const { isArrowDown, isArrowUp, isTab } = this.getKeyboardNavigation(e)
    if (isArrowDown || isArrowUp) {
      this.navigateOptions(isArrowDown, select)
    }
    if (isTab && avv_select_is_open(select)) this.hideSelect(select)
  }

  hideSelect(select: HTMLSelectElement) {
    if (select) avv_select_close(select)
  }

  getSelectFromEvent(e: KeyboardEvent) {
    if (!e.target || !IsHTMLElement(e.target)) return
    return e.target.closest('avv-select') as HTMLSelectElement
  }

  navigateOptions(down: boolean, select: HTMLSelectElement) {
    const options = avv_select_get_all_options(select).filter(
      (option) => !option.classList.contains('hidden')
    )
    const optionWithTabindex = options.find(
      (option) => option.getAttribute('tabindex') == '0'
    )
    const optionToFocus = down
      ? optionWithTabindex?.nextElementSibling
      : optionWithTabindex?.previousElementSibling
    if (!IsHTMLElement(optionToFocus)) return
    if (!IsHTMLElement(optionWithTabindex)) return
    if (!IsHTMLElement(options[0])) return
    if (!optionWithTabindex) this.focusOption(down, options[0])
    else if (optionToFocus)
      this.focusOption(down, optionToFocus, optionWithTabindex)
  }

  focusOption(
    down: boolean,
    option: HTMLElement,
    optionToDefocus?: HTMLElement
  ) {
    option.setAttribute('tabindex', '0')
    option.focus()
    if (optionToDefocus) optionToDefocus.setAttribute('tabindex', '-1')
  }

  getKeyboardNavigation(e: KeyboardEvent) {
    const isArrowDown = e.key == 'ArrowDown'
    const isArrowUp = e.key == 'ArrowUp'
    const isEnter = e.key == 'Enter'
    const isTab = e.key == 'Tab'

    return { isArrowDown, isArrowUp, isEnter, isTab }
  }

  ensureTabIndex(target: HTMLElement) {
    const options = avv_select_get_all_options(target)
    const optionsWithoutTabIndex = options.filter(
      (option) => !option.hasAttribute('tabindex')
    )
    optionsWithoutTabIndex.forEach((option) =>
      option.setAttribute(
        'tabindex',
        option.getAttribute('selected') == 'true' ? '0' : '-1'
      )
    )
  }
}
