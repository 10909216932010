import { Key } from '@avvoka/shared'
import { Controller } from 'stimulus'

export default class NestedFormController extends Controller {
  static targets = ['items', 'template', 'table', 'footer']

  declare readonly itemsTarget: HTMLInputElement
  declare readonly templateTarget: HTMLInputElement
  declare readonly tableTarget: HTMLInputElement
  declare readonly footerTarget: HTMLInputElement
  declare _itemClass: string
  declare _removeItemClasses: string[]

  connect() {
    this._removeItemClasses = []
    this._itemClass = this.data.get('itemClass') || 'nested-item'

    if (this.data.has('removeClasses')) {
      this._removeItemClasses = this.data.get('removeClasses')!.split(' ')
    }
    if (this.itemsTarget.dataset.preventEnter)
      this.itemsTarget.addEventListener('keypress', this.preventEnter)
  }

  add(e: Event) {
    e.preventDefault()

    const beforeEvent = new CustomEvent('nested_form_controller.add.before', {
      detail: this
    })
    document.dispatchEvent(beforeEvent)

    const recordType = this.templateTarget.innerHTML.includes('NEW_RECORD')
      ? 'NEW_RECORD'
      : 'NEW_NESTED_RECORD'
    const content = this.templateTarget.innerHTML.replace(
      new RegExp(recordType, 'g'),
      new Date().getTime()
    )
    const nestedFooter = this.itemsTarget.querySelector('.nested_footer')

    if (nestedFooter) nestedFooter.insertAdjacentHTML('beforebegin', content)
    else this.itemsTarget.insertAdjacentHTML('beforeend', content)

    // TODO: refactor to after event
    this.after_add()

    const afterEvent = new CustomEvent('nested_form_controller.add.after', {
      detail: this
    })
    document.dispatchEvent(afterEvent)
  }

  addByEnter(e: KeyboardEvent) {
    if (e.key === Key.Enter) {
      this.add(e)
    }
  }

  remove(event: Event) {
    event.preventDefault()

    const eventTargent = event.target as HTMLElement
    const wrapper = eventTargent.closest(`.${this._itemClass}`) as HTMLElement

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      const input = wrapper.querySelector("input[name*='_destroy']")
      if (input) input.value = 1

      if (this.data.has('removeClasses')) {
        wrapper.classList.add(...this._removeItemClasses)
      } else {
        wrapper.style.display = 'none'
      }
    }

    this.notifyNestedItemRemoved()
  }

  notifyNestedItemRemoved() {
    document.dispatchEvent(new CustomEvent('nested_item_removed'))
  }

  after_add() {
    // Init checkboxes
    if ($.material) $.material.options.checkbox = true
    if ($.material)
      $.material.options.checkboxElements = 'input[type="checkbox"]'
    if ($.material) $.material.init()
  }

  toggle() {
    // this.targets.table.classList.toggle('hidden')
    document.querySelector('.table-toggle')?.classList.toggle('hidden')
  }

  preventEnter(e: Event) {
    const keyboardEvent = e as KeyboardEvent
    if (keyboardEvent.key === 'Enter') e.preventDefault()
  }
}
