import { Controller } from "@hotwired/stimulus"
import Utils from "../../features/utils";

import AdminTemplatesDialog from '../../features/admin_templates/AdminTemplatesDialog.vue'

export default class AdminTemplatesController extends Controller {
  organisations?: Array<{ name: string, id: number }>
  profiles?: Array<{ name: string, id: number }>

  async open () {
    await this._fetch()

    this._showDialog()
  }

  _showDialog () {
    window.avv_dialog({
      vueDialog: AdminTemplatesDialog,
      callback: () => {},
      props: {
        profiles: this.profiles,
        organisations: this.organisations,
        templates: this._templates()
      }
    })
  }

  _templates () {
    const form = document.getElementById('templates-form')!
    const checkboxes = form.querySelectorAll<HTMLInputElement>('input[type="checkbox"]:not([data-check-all]):not([data-skip])')

    return Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value)
  }

  async _fetch () {
    if (this.organisations && this.profiles) return

    const { data } = await Utils.axios.get('/admin/templates/profiles_organisations')
    this.organisations = data.organisations
    this.profiles = data.profiles
  }
}
