<template>
  <GlobalDialog class="flex flex-col gap-4 w-[450px] dialog avv-gray-100 avv-bg">
    <h2 class="font-bold" v-text="localize('title')" />
    <!-- AI comments -->
    <AIComments :logs="dialogData.ai.logs" :loading="dialogData.ai.loading" :error="dialogData.ai.error" />
    <!-- Text -->
    <Textarea :height200px="dialogData.ai.logs.length < 1" @input="textInput" />
    <!-- Buttons -->
    <div class="flex justify-between">
      <div class="flex gap-x-2.5 items-center">
        <PreStyleButton mode="primary_full" @click="close">
          {{ localize('cancel') }}
        </PreStyleButton>
        <AToggle :active="dialogData.ai.enforceAnswers" :title="localize('toggle')" @click="toggleEnforceAnswers" />
      </div>
      <div class="flex gap-x-2.5">
        <PreStyleButton v-if="buttonGenerateEntriesShow" mode="primary_full" :disable="buttonGenerateEntriesDisable" @click="sendDocToAI">
          {{ localize('next') }}
        </PreStyleButton>
        <PreStyleButton v-if="dialogData.ai.entriesCreated" mode="primary_full" @click="createDoc"> {{ localize('create_document') }} </PreStyleButton>
      </div>
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>
import { defineComponent, ref, computed } from 'vue'
import AiApi from '@api/AiApi'
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import PreStyleButton from '../_abstract/PreStyleButton.vue'
import AIComments from './components/AIComments.vue'
import Textarea from './components/Textarea.vue'
import AToggle from '../_abstract/AToggle.vue'
import { createLog, createAndSubmitForm } from './utils'
import type { AIDialogFromTextData } from './utils'

const props = defineProps<{
  templates?: number[]
}>()

const emit = defineEmits<{
  (e: 'callback'): void
}>()

const dialogData = ref<AIDialogFromTextData>({
  text: {
    value: '',
    notTouched: true
  },
  ai: {
    logs: [],
    loading: false,
    firstTime: true,
    entriesCreated: false,
    error: false,
    entries: {},
    enforceAnswers: false
  }
})

//Buttons conditions

const buttonGenerateEntriesShow = computed(() => {
  return !dialogData.value.ai.entriesCreated && !dialogData.value.ai.loading
})
const buttonGenerateEntriesDisable = computed(() => {
  return dialogData.value.text.notTouched
})

//Actions

const close = () => {
  emit('callback')
}

const toggleEnforceAnswers = () => {
  dialogData.value.ai.enforceAnswers = !dialogData.value.ai.enforceAnswers
}

const textInput = (e: Event) => {
  const eTarget = e.target as HTMLInputElement
  if (eTarget.value !== '') dialogData.value.text.notTouched = false
  else dialogData.value.text.notTouched = true
  dialogData.value.ai.entriesCreated = false
  dialogData.value.ai.error = false
  dialogData.value.text.value = eTarget.value
}

const sendDocToAI = () => {
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const data = {
    text: dialogData.value.text.value,
    template_id: props.templates![0],
    enforce_answers: dialogData.value.ai.enforceAnswers
  }

  AiApi.docxToEntries({
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((response) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.entriesCreated = true
      dialogData.value.ai.entries = response.entries
      createLog(response.evidence)

      dialogData.value.ai.logs.push({
        fileName: 'text',
        body: createLog(response.evidence)
      })
    })
    .catch((e) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.error = true
    })
}

const createDoc = () => {
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const data = [
    {
      name: 'ordered_template_ids[]',
      value: props.templates![0].toString()
    },
    {
      name: 'entries',
      value: JSON.stringify(dialogData.value.ai.entries)
    }
  ]
  createAndSubmitForm('../documents/new', data)
}

const localize = (key: string) => window.localizeText(`documents.ai_create.from_text.dialog.${key}`)
</script>
<script lang="ts">
export default defineComponent({ name: 'AICreateFromTextDialog' })
</script>
