import { QuestionnaireLoopResolution } from '../features/QuestionnaireLoops'
import { StimulusControllerBase } from './base_controller'
import type { ExposeStimulus } from './base_controller'

export interface TemplateSettingsController
  extends ExposeStimulus<typeof TemplateSettingsController> {}
export class TemplateSettingsController extends StimulusControllerBase {
  static targets = ['questionnaireResolutionMode', 'showCharacterCounterInput'] as const

  connect() {
    this.listenToQuestionnaireResolutionModeChange()
    this.listenToShowCharacterCounterChange()
  }

  listenToQuestionnaireResolutionModeChange() {
    const questionnaireResolutionMode = this.questionnaireResolutionModeTarget
    questionnaireResolutionMode.addEventListener('change', (e) => {
      this.onQuestionnaireResolutionModeChange(e)
    })
  }

  onQuestionnaireResolutionModeChange(e: Event) {
    const target = e.target as HTMLSelectElement
    const value = target.value
    if (!Object.values(QuestionnaireLoopResolution).includes(value)) {
      throw new Error(`Invalid questionnaire resolution mode: ${value}`)
    }
    AvvStore.state.questionnaireResolutionMode =
      value as QuestionnaireLoopResolution
  }

  listenToShowCharacterCounterChange() {
    const showCharacterCounterInput = this.showCharacterCounterInputTarget
    showCharacterCounterInput.addEventListener('change', () => {
      this.onShowCharacterCounterInputChange()
    })
  }

  onShowCharacterCounterInputChange() {
    AvvStore.state.enable_character_counter = this.showCharacterCounterInputTarget.checked
  }

  onSignMethodChange(e) {
    const signingMethod = !!e.target.value
    this.handleSkipToSignCheckBoxDisplay(signingMethod)
  }

  handleSkipToSignCheckBoxDisplay(value: boolean) {
    const formGroup = document.getElementById(
      'skip-to-signature-group'
    ) as HTMLElement
    formGroup.style.display = value ? 'block' : 'none'
    if (!value) {
      const checkbox = document.getElementById(
        'template_skip_to_signature'
      ) as HTMLInputElement
      checkbox.checked = false
    }
  }
}

export default TemplateSettingsController
