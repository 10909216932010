<template>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition transition-opacity z-5000"
  >
    <div class="flex items-center justify-center min-h-full">
      <div
        class="bg-white rounded-lg text-sm overflow-hidden shadow-xl max-w-lg w-full p-6 flex flex-col gap-6"
      >
        <div class="text-xl text-center" v-text="localize('title')" />
        <div>
          <label
            for="new-folder-dialog-input"
            class="font-bold"
            v-text="localize('label')"
          />
          <input
            id="new-folder-dialog-input"
            v-model="nameModel"
            type="text"
            :placeholder="localize('placeholder')"
            class="avv-input-tw"
          />
          <div v-if="nameValidation" class="text-red-400 mt-2 text-xs">
            &bullet; {{ nameValidation }}
          </div>
        </div>
        <div class="flex justify-between gap-4">
          <button
            class="avv-button secondary w-full"
            @click="performClose"
            v-text="localize('cancel')"
          />
          <button
            class="avv-button w-full"
            :disabled="!canSave"
            @click="performSave"
            v-text="localize('create')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'

const props = defineProps<{
  reservedNames: string[]
}>()

const emit = defineEmits<{
  (e: 'callback', value?: string): void
}>()

const nameModel = ref('')
const name = computed(() => nameModel.value.trim())

const nameValidation = computed(() => {
  if (!name.value) {
    return localize('errors.blank')
  } else if (props.reservedNames.includes(name.value)) {
    return localize('errors.unavailable')
  }
})

const performSave = () => emit('callback', name.value)
const performClose = () => emit('callback')

const canSave = computed(
  () => name.value && !props.reservedNames.includes(name.value)
)

const localize = (key: string, args?: any) =>
  window.localizeText(`integrations.dialogs.new_folder.${key}`, args)
</script>
