<template>
  <GlobalDialog class="flex flex-col gap-4 w-[450px] dialog avv-gray-100 avv-bg">
    <h2 class="font-bold" v-text="localize(`title.${dialogData.file.errors ? 'error' : 'default'}`)" />
    <!-- File -->
    <File v-if="dialogData.file.file" :file_name="dialogData.file.name" @close="retry" />
    <!-- AI comments -->
    <AIComments :logs="dialogData.ai.logs" :loading="dialogData.ai.loading" :error="dialogData.ai.error" />
    <!-- Errors -->
    <Errors v-if="dialogData.file.errors" :errors="dialogData.file.errors" />
    <!-- File upload-->
    <FileUpload v-if="fileUpoad" :height200px="dialogData.ai.logs.length < 1" :readyForUpload="fileUpoad" :loading="dialogData.file.loading" type="docx" @upload="chooseFile">
      <span class="font-bold avv-primary-color" v-text="localize('dropzone.label')" />
      {{ localize('dropzone.text') }}
    </FileUpload>
    <!-- File -->
    <File v-else-if="dialogData.ai.logs.length > 0 && !dialogData.file.errors" :file_name="dialogData.file.name" @close="retry" />
    <!-- Buttons -->
    <div class="flex justify-between">
      <div class="flex gap-x-2.5 items-center">
        <PreStyleButton mode="primary_full" @click="close">
          {{ localize('cancel') }}
        </PreStyleButton>
        <AToggle :active="dialogData.ai.enforceAnswers" :title="localize('toggle')" @click="toggleEnforceAnswers" />
      </div>
      <div class="flex gap-x-2.5">
        <PreStyleButton v-if="buttonRetryShow" mode="primary_full" @click="retry">
          {{ localize('retry') }}
        </PreStyleButton>
        <PreStyleButton v-if="buttonGenerateEntriesShow" mode="primary_full" :disable="buttonGenerateEntriesDisable" @click="sendDocToAI">
          {{ localize('next') }}
        </PreStyleButton>
        <PreStyleButton v-if="dialogData.ai.entriesCreated" mode="primary_full" @click="createDoc"> {{ localize('create_document') }} </PreStyleButton>
      </div>
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>
import { defineComponent, ref, computed } from 'vue'
import AiApi from '@api/AiApi'
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import PreStyleButton from '../_abstract/PreStyleButton.vue'
import Errors from './components/Errors.vue'
import File from './components/File.vue'
import AIComments from './components/AIComments.vue'
import FileUpload from './components/FileUpload.vue'
import AToggle from '../_abstract/AToggle.vue'
import { createLog, createAndSubmitForm } from './utils'
import type { AIDialogFromDocData } from './utils'

const props = defineProps<{
  templates?: number[]
}>()

const emit = defineEmits<{
  (e: 'callback'): void
}>()

const dialogData = ref<AIDialogFromDocData>({
  file: {
    name: '',
    file: null,
    loading: false,
    link: '',
    size: null,
    errors: null
  },
  ai: {
    logs: [],
    loading: false,
    firstTime: true,
    entriesCreated: false,
    error: false,
    entries: {},
    enforceAnswers: false
  }
})

//File Upoad conditions

const fileUpoad = computed(() => {
  return (!dialogData.value.file.file || dialogData.value.ai.entriesCreated || dialogData.value.file.loading) && !dialogData.value.file.errors
})

//Buttons conditions

const buttonRetryShow = computed(() => {
  return dialogData.value.file.file && dialogData.value.file.errors
})
const buttonGenerateEntriesShow = computed(() => {
  return !dialogData.value.file.errors && !dialogData.value.ai.entriesCreated && !dialogData.value.ai.loading
})
const buttonGenerateEntriesDisable = computed(() => {
  return !!(!dialogData.value.file.file || dialogData.value.file.errors)
})

//Actions

const close = () => {
  emit('callback')
}

const retry = () => {
  dialogData.value.file.name = ''
  dialogData.value.file.file = null
  dialogData.value.file.errors = null
  dialogData.value.file.link = ''
  dialogData.value.file.size = null
  dialogData.value.ai.entriesCreated = false
  dialogData.value.ai.entries = {}
  dialogData.value.ai.error = false
}

const toggleEnforceAnswers = () => {
  dialogData.value.ai.enforceAnswers = !dialogData.value.ai.enforceAnswers
}

const chooseFile = (e: Event) => {
  retry()

  const input = e.target as HTMLInputElement
  dialogData.value.file.file = input.files![0] as File

  if (dialogData.value.file.file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    dialogData.value.file.errors = { file_generic: true }
    return console.warn('File type mismatch')
  }

  if (dialogData.value.file.file.size > 256e6) {
    dialogData.value.file.errors = { file_large: true }
    return console.warn('File too large')
  }
  dialogData.value.file.name = dialogData.value.file.file.name
}

const sendDocToAI = () => {
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const data = {
    file: dialogData.value.file.file,
    template_id: props.templates![0],
    enforce_answers: dialogData.value.ai.enforceAnswers
  }

  AiApi.docxToEntries({
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((response) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.entriesCreated = true
      dialogData.value.ai.entries = response.entries
      createLog(response.evidence)

      dialogData.value.ai.logs.push({
        fileName: dialogData.value.file.name,
        body: createLog(response.evidence)
      })
    })
    .catch((e) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.error = true
    })
}

const createDoc = () => {
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const data = [
    {
      name: 'ordered_template_ids[]',
      value: props.templates![0].toString()
    },
    {
      name: 'entries',
      value: JSON.stringify(dialogData.value.ai.entries)
    }
  ]
  createAndSubmitForm('../documents/new', data)
}

const localize = (key: string) => window.localizeText(`documents.ai_create.from_doc.dialog.${key}`)
</script>
<script lang="ts">
export default defineComponent({ name: 'AICreateFromDocDialog' })
</script>
