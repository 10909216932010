<template>
  <div
    v-if="dialogLoading"
    class="avv_dialog-wrapper"
  >
    <div class="flex justify-center items-center h-full">
      <div class="flex loader"></div>
    </div>
  </div>
  <GlobalDialog
    v-else
    class="square bg-white !p-4 min-w-[60vw] !rounded-md"
  >
    <div
      class="font-bold text-xl"
      v-text="localize('title', { scope, name })"
    />
    <div
      v-if="author.name"
      class="text-gray-500"
      v-text="
        localize(author.self ? 'editing_notice' : 'replacing_notice', {
          author: author.name
        })
      "
    />
    <div
      class="flex gap-0 flex-wrap w-full my-4 text-gray-800 text-sm"
      :class="{ 'pointer-events-none': isLoading }"
    >
      <template v-for="step of currentLocation" :key="step.id">
        <button
          class="flex cursor-pointer rounded p-1 hover:bg-gray-200"
          @click="selectItem(step.id)"
        >
          <i
            class="material-symbols-outlined"
            :class="{ 'mr-2': step.name }"
            aria-hidden="true"
            v-text="getStepIcon(step.type)"
          /><span v-if="step.name" class="pr-1" v-text="step.name" />
        </button>
        <div
          v-if="step.id !== currentStep.id"
          class="flex items-center text-gray-500"
        >
          <span class="material-symbols-outlined" aria-hidden="true"
            >chevron_right</span
          >
        </div>
      </template>
    </div>
    <div class="flex h-[60vh]">
      <div class="rounded border flex flex-col w-3/5">
        <div class="p-1 flex gap-1">
          <Search
            :data="searchData"
            :config="searchConfig"
            :icon="'filter_alt'"
            class="w-full"
            @change="(type) => updateCurrentStepSearch(true, type)"
          />
          <button
            v-if="false && currentLocationHasFiles"
            :disabled="isLoading"
            class="enabled:cursor-pointer inline-flex text-gray-600 justify-center items-center rounded-md border shadow-sm px-2 text-base font-medium focus:outline-none text-sm"
            :title="
              localize(showFiles ? 'buttons.hide_files' : 'buttons.show_files')
            "
            @click="showFiles = !showFiles"
          >
            <i
              class="material-symbols-outlined"
              v-text="showFiles ? 'draft' : 'scan_delete'"
            />
          </button>
          <button
            v-if="isPathValidForFolder"
            :disabled="isLoading"
            class="enabled:cursor-pointer w-1/3 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none text-sm"
            @click="newFolderOpen"
            v-text="localize('buttons.folder')"
          />
        </div>
        <div class="h-full overflow-y-scroll">
          <div v-if="isLoading" class="flex justify-center items-center h-full">
            <div class="flex loader"></div>
          </div>
          <template v-else>
            <template v-for="item of filteredItems" :key="item.id">
              <div
                v-if="item.type === 'file'"
                class="p-2 flex cursor-default opacity-50 w-full"
              >
                <i
                  class="material-symbols-outlined mr-2"
                  aria-hidden="true"
                  v-text="'draft'"
                />
                <div v-text="item.name" />
              </div>
              <button
                v-else
                class="cursor-pointer hover:bg-gray-200 p-2 flex w-full"
                @click="selectItem(item.id)"
              >
                <i
                  class="material-symbols-outlined mr-2"
                  aria-hidden="true"
                  v-text="getStepIcon(item.type)"
                />
                <div v-text="item.name" />
              </button>
            </template>
          </template>
        </div>
      </div>
      <div class="flex flex-col gap-1 overflow-y-scroll w-2/5">
        <template v-for="(keys, index) of PERFORM_OPTIONS_CONFIG" :key="index">
          <div class="flex">
            <input
              :id="`category${index}`"
              type="checkbox"
              class="avv-button avv-checkbox compact"
              :class="categoryState[index]"
              @click="toggleCategory(index)"
            />
            <label
              class="cursor-pointer font-bold mt-1"
              :for="`category${index}`"
              v-html="localize(`heading${index + 1}_html`, { name })"
            />
          </div>
          <div
            v-for="key of keys"
            :key="key.key"
            class="ml-8 flex items-center"
          >
            <ACheckbox
              :id="key.key"
              v-model="performOptions[key.key]"
              class="compact"
            />
            <label
              class="cursor-pointer"
              :for="key.key"
              :title="key.hint"
              v-text="localize(`options.${key.key}.title`)"
            />
            <i
              v-if="key.hint"
              class="material-icons-outlined ml-2 text-gray-500"
              style="font-size: 1.25rem"
              aria-hidden="true"
              :title="key.hint"
              >info</i
            >
          </div>
        </template>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <button
        class="avv-button secondary w-1/6"
        @click="performClose"
        v-text="localize('buttons.cancel')"
      />
      <button
        class="avv-button w-1/6"
        :disabled="saveButtonDisabled"
        @click="performSave"
        v-text="saveButtonText"
      />
    </div>
    <NewFolderDialog
      v-if="newFolder"
      :reserved-names="newFolderBlacklist"
      @callback="newFolderCallback"
    />
  </GlobalDialog>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, reactive } from 'vue'
import { type IntegrationStore, type IntegrationStores } from './index'

import GlobalDialog from '../dialogs/GlobalDialog.vue'
import ACheckbox from '../_abstract/ACheckbox.vue'

import NewFolderDialog from './components/NewFolderDialog.vue'
import Search from '../search/Search.vue'
import {
  reactiveData,
  type BadgeConfig,
  type FilterConfigEntry,
  type BadgeEmit
} from '../search/shared'

import {
  type Synchronization,
  type SynchronizationOptions,
  type Location,
  type LocationNode,
  stripLocation,
  stripLocationNode,
  dispatchSynchronizationApi,
  PERFORM_OPTIONS_CONFIG
} from './shared'

const props = defineProps<{
  integration: 'string'
  scope: 'Template' | 'Document'
  ids: string[]
  store: IntegrationStores
}>()

const emit = defineEmits<{
  (e: 'callback', value: boolean): void
}>()

const integration = computed<string>(() => props.integration)
const store = computed<IntegrationStore>(() => props.store[integration.value])
const scope = computed<string>(() => props.scope)
const ids = computed<string[]>(() => props.ids ?? [])
const name = computed<string>(() => store.value.name)

const author = ref<{ name: string; self: boolean }>({ name: '', self: false })

const showFiles = ref(true)

const searchData = reactiveData()
const searchActive = computed(() => searchData.filters.length > 0)
const searchConfig = computed<BadgeConfig>(() => ({
  sort: [],
  filters: currentSearch.value ?? []
}))

// Current synchronization data
const synchronizationData = computed(() => {
  if (ids.value.length == 1) {
    return {
      integration: integration.value,
      scope: scope.value,
      id: ids.value[0]
    }
  } else {
    return {
      integration: integration.value,
      scope: null,
      id: null
    }
  }
})

// Fetch data
const queryData = async function <T>(queryData: any): Promise<T> | never {
  try {
    return await dispatchSynchronizationApi('query', {
      params: { integration_id: integration.value },
      data: Object.assign({ query: queryData }, synchronizationData.value)
    })
  } catch (e: any) {
    window.avv_toast({
      message: e.message,
      type: 'error',
      icon: 'error'
    })

    emit('callback', false)

    throw new Error(e.message)
  }
}

// Current file path, including root
const currentLocation = ref<Location>([{ id: 'root', name: '', type: 'root' }])
const currentSearch = ref<FilterConfigEntry[] | null>(null)
const currentStep = computed<LocationNode>(
  () => currentLocation.value[currentLocation.value.length - 1]
)

// Callbacks
const selectItem = (stepId: string) => {
  const index = currentLocation.value.findIndex(({ id }) => id === stepId)
  if (index !== -1) {
    clearSearch()

    // We clicked at item in the location
    currentLocation.value.splice(
      index + 1,
      currentLocation.value.length - index
    )
  } else {
    // We clicked at an item in the list
    const item = currentStep.value.items?.find(({ id }) => id === stepId)
    if (!item) return

    clearSearch()
    currentLocation.value.push(stripLocationNode(item))
  }
}

const clearSearch = () => {
  if (!currentSearch.value) return

  searchData.filters = []
  currentSearch.value = null
  currentStep.value.items = undefined
}

const updateCurrentStepSearch = async (
  clearItems: boolean,
  type?: BadgeEmit
) => {
  // Ignore call if search is not setup
  if (!currentSearch.value) return
  // Ignore search emits from search
  if (type === 'search') return

  forceLoading.value = true

  const { items, filters } = await queryData<{
    items: Location
    filters: FilterConfigEntry[]
  }>({
    operation: 'search',
    location: stripLocation(currentLocation.value.slice(1)),
    query: searchData.filters.reduce(
      (memo: Record<string, string | null>, entry) => {
        memo[entry.key] = entry.values[0]

        return memo
      },
      Object.create(null)
    )
  })

  currentSearch.value = filters

  if (searchActive.value) {
    currentStep.value.items = items
  } else if (clearItems) {
    currentStep.value.items = undefined
  }

  forceLoading.value = false
}

const performClose = () => {
  emit('callback', false)
}

const saveButtonLoading = ref(false)
const dialogLoading = ref(true)

const performSave = async () => {
  saveButtonLoading.value = true

  try {
    const data = {
      synchronization: {
        integration: integration.value,
        scope: scope.value,
        ids: ids.value,
        location: stripLocation(currentLocation.value.slice(1)),
        options: performOptions
      }
    }

    await dispatchSynchronizationApi('create', { data })

    window.avv_toast({
      message: localize('toast.success'),
      type: 'success',
      icon: 'download_done'
    })

    emit('callback', true)
  } catch (e: any) {
    saveButtonLoading.value = false

    window.avv_toast({
      message: e.message,
      type: 'error',
      icon: 'error'
    })
  }
}

const forceLoading = ref(false)

const isLoading = computed(() => forceLoading.value || !currentStep.value.items)
watch(isLoading, (value) => (searchData.freeze = value))

const newFolderBlacklist = computed(
  () => currentStep.value.items?.map((item) => item.name) || []
)
const newFolder = ref(false)

const newFolderOpen = () => {
  newFolder.value = true
}

const newFolderCallback = async (name: string | undefined) => {
  newFolder.value = false
  if (!name) return

  forceLoading.value = true

  // Create folder
  const id = await queryData<string>({
    operation: 'create_folder',
    location: stripLocation(currentLocation.value.slice(1)),
    name
  })

  currentStep.value.items!.splice(0, 0, { id, name, type: 'folder' })
  currentLocation.value.push({ id, name, type: 'folder' })

  forceLoading.value = false
}

const filteredItems = computed(() => {
  const label = searchData.search.toLowerCase().trim()

  let items = currentStep.value.items!
  items = label
    ? items.filter(({ name }) => name.toLowerCase().includes(label))
    : items
  items = showFiles.value ? items : items.filter(({ type }) => type !== 'file')

  return items
})

const updateCurrentStepItems = async (location: Location) => {
  const data = await queryData<Location>({
    operation: 'list',
    location: stripLocation(location)
  })

  currentStep.value.items = stripLocation(data)
}

// Run query when path changes
watch(currentLocation.value, async (location: Location) => {
  const lastStep = location[location.length - 1]

  if (!lastStep.items) {
    await updateCurrentStepItems(location.slice(1))
  }

  if (isNodeValidFor(lastStep, 'search') && !currentSearch.value) {
    currentSearch.value = []
    await updateCurrentStepSearch(false)
  }
})

const currentLocationHasFiles = computed(() =>
  currentStep.value.items?.some(({ type }) => type === 'file')
)

const performOptions = reactive<SynchronizationOptions>({
  sync_minor: false,
  sync_major: false,
  sync_executed: false,
  push_minor: false,
  push_major: false,
  push_current: true,
  format_docx: false,
  format_pdf: false
})

const localize = (key: string, args?: any) =>
  window.localizeText(`integrations.dialogs.synchronizations.${key}`, args)

const categoryState = computed(() => {
  return PERFORM_OPTIONS_CONFIG.map((keysConfig) => {
    const keys = keysConfig.map(({ key }) => key)
    const keysChecked = keys.reduce(
      (memo, key) => memo + (performOptions[key] ? 1 : 0),
      0
    )

    return {
      checked: keysChecked > 0,
      indeterminate: keysChecked < keys.length
    }
  })
})

const toggleCategory = (index: number) => {
  const keys = PERFORM_OPTIONS_CONFIG[index].map(({ key }) => key)
  const value = !keys.some((key) => performOptions[key])

  for (const key of keys) {
    performOptions[key] = value
  }
}

// Query sites when dialog opens
onMounted(async () => {
  await updateCurrentStepItems([])

  if (ids.value.length == 1) {
    try {
      const data = await dispatchSynchronizationApi<{
        synchronization: Synchronization | null
        author: string
        author_self: boolean
      } | null>('get', { params: synchronizationData.value })

      if (data) {
        if (data.synchronization) {
          const {
            location,
            sync_minor,
            sync_major,
            sync_executed,
            format_docx,
            format_pdf
          } = data.synchronization

          // Path
          for (const step of location) {
            currentLocation.value.push(step)
          }

          // Sync
          performOptions.sync_minor = sync_minor ?? false
          performOptions.sync_major = sync_major ?? false
          performOptions.sync_executed = sync_executed ?? false
          performOptions.format_docx = format_docx ?? false
          performOptions.format_pdf = format_pdf ?? false

          // Clear current flag
          performOptions.push_current = false
        }

        // Set author so we can display custom status
        author.value = {
          name: data.author,
          self: data.author_self
        }
      }
    } catch (e: any) {
      window.avv_toast({
        message: e.message,
        type: 'error',
        icon: 'error'
      })

      emit('callback', false)

      throw new Error(e.message)
    }
  }

  dialogLoading.value = false
})

const getStepIcon = (type: string) => {
  return store.value.resources[type]?.icon ?? 'public'
}

const isPathValidFor = (field: keyof IntegrationStore['resources'][string]) => {
  return isNodeValidFor(currentStep.value, field)
}

const isNodeValidFor = (
  node: LocationNode,
  field: keyof IntegrationStore['resources'][string]
) => {
  return store.value.resources[node.type]?.[field] ?? false
}

const isPathValidForFile = computed(
  () => !searchActive.value && isPathValidFor('file')
)
const isPathValidForFolder = computed(
  () => !searchActive.value && isPathValidFor('folder')
)

const saveButtonText = computed(() => {
  const key = `buttons.${
    author.value.name ? (author.value.self ? 'update' : 'replace') : 'save'
  }${saveButtonLoading.value ? '_loading' : ''}`

  return localize(key)
})

const saveButtonDisabled = computed(() => {
  return (
    saveButtonLoading.value ||
    !isPathValidForFile.value ||
    !(performOptions.format_pdf || performOptions.format_docx) ||
    !(
      performOptions.push_current ||
      performOptions.push_major ||
      performOptions.push_minor
    )
  )
})
</script>
