import { stopAndPrevent } from '../../features/_abstract/utils/event'
import { type ExposeStimulus, StimulusControllerBase } from '../base_controller'

import DatasheetRowDialog from './../../features/datasheets/rows/DatasheetRowDialog.vue'

export interface RowsController extends ExposeStimulus<typeof RowsController> {}
export class RowsController extends StimulusControllerBase {
  create(e: PointerEvent) {
    stopAndPrevent(e)

    window.avv_dialog({
      vueDialog: DatasheetRowDialog,
      callback: (v: boolean) => {
        if (v) window.location.reload()
      },
      props: {
        datasheetId: this.#getId(e, 'data-datasheet-id'),
        edit: true
      }
    })
  }

  copy(e: PointerEvent) {
    stopAndPrevent(e)

    window.avv_dialog({
      vueDialog: DatasheetRowDialog,
      callback: (v: boolean) => {
        if (v) window.location.reload()
      },
      props: {
        datasheetId: this.#getId(e, 'data-datasheet-id'),
        datasheetRowId: this.#getId(e, 'data-datasheet-row-id'),
        edit: true,
        copy: true
      }
    })
  }

  update(e: PointerEvent) {
    stopAndPrevent(e)

    window.avv_dialog({
      vueDialog: DatasheetRowDialog,
      callback: (v: boolean) => {
        if (v) window.location.reload()
      },
      props: {
        datasheetId: this.#getId(e, 'data-datasheet-id'),
        datasheetRowId: this.#getId(e, 'data-datasheet-row-id'),
        edit: true
      }
    })
  }

  #getId(event: Event, name: string) {
    return (event.currentTarget as HTMLElement).getAttribute(name) as string
  }
}

export default RowsController
