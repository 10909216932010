import { Controller } from "stimulus"

const DIALOG_CLASSES = ['modal', 'modal-dialog']

export default class extends Controller {
  static values = {
    trigger: { type: String, default: '' }
  }

  open (event: PointerEvent) {
    const buttonDataset = (event.currentTarget as HTMLElement).dataset

    const modalId = buttonDataset.openModal
    if (modalId) {
      // Event propagation can be enabled via `data-open-modal-event="propagate"` attribute
      if (buttonDataset.openModalEvent !== 'propagate') event.preventDefault()

      const modal = document.getElementById(modalId)
      if (modal) {
        this.openModal(modal)
      } else {
        console.error(`Cannot open modal with id '${modalId}' because it does not exist within the document!`)
      }
    }
  }

  close (event: PointerEvent) {
    const eventTarget = event.target as HTMLElement
    const modal = eventTarget.closest('.modal')
    if (modal && !eventTarget?.classList?.contains('no-close-action')) {
      this.closeModal(modal as HTMLElement)
    }
  }

  confirm (event: PointerEvent) {
    const modal = (event.target as HTMLElement).closest(".modal")
    if (modal) {
      this.dispatchEvent(modal as HTMLElement, 'resolved')
      this.close(event)
    }
  }

  listenOnOutsideClick (modalId: string) {
    const handleWindowClick = (event: MouseEvent) => {
      if (!this.isWithinDialog(event.target as HTMLElement)) {
        const modal = document.getElementById(modalId)
        if (modal) {
          this.closeModal(modal)
        }
      }
    }

    window.addEventListener('click', handleWindowClick)
  }

  isWithinDialog (element: HTMLElement | null): boolean {
    if (!element) return false

    // Trigger element
    if (element.id == this.triggerValue) return true

    // Dialog element
    if (DIALOG_CLASSES.some((klass) => element.classList.contains(klass))) return true

    // Stop element
    if (element.id == 'main' || element.tagName === 'HTML') return false

    return this.isWithinDialog(element.parentElement)
  }

  openModal (modal: HTMLElement) {
    modal.style.display = 'flex'

    this.dispatchEvent(modal, 'open')
    this.listenOnOutsideClick(modal.id)
  }

  closeModal (modal: HTMLElement) {
    modal.style.display = 'none'

    this.dispatchEvent(modal, 'closed')
  }

  dispatchEvent (modal: HTMLElement, type: 'open' | 'closed' | 'resolved') {
    const event = new CustomEvent(`${modal.id}-${type}`, { detail: { modal } })

    document.dispatchEvent(event)
  }
}
