
type AsyncVoidFunction = () => Promise<void>
type CallbackFunction = VoidFunction | AsyncVoidFunction

export default class Interval {
  _ref?: number

  constructor(
    public callback: CallbackFunction,
    public delayInMs: number,
    public exponentialBackoffRetries: number = 0
  ) {
    this.go()
  }

  go(){
    if(this.exponentialBackoffRetries) {
      // We need to use a private method to handle the recursive setTimeout calls
      // The method will assign the setTimeout to this._ref, so we can clear it later
      this._goExponentialBackoff(
        {retries: this.exponentialBackoffRetries, delay: this.delayInMs},
        {retries: this.exponentialBackoffRetries, delay: this.delayInMs},
        this.callback
      )
    } else {
      this._ref = setInterval(this.callback, this.delayInMs)
    }
  }

  // A private method that initiatively handles calling a callback function using a setTimeout with configurable retry and delay settings.
  // Utilizes an exponential backoff strategy to progressively increase delay on repeated failures.
  _goExponentialBackoff(initial: {retries: number, delay: number}, current: {retries: number, delay: number}, callback: CallbackFunction){
    this._ref = setTimeout(async () => {
      try {
        const result = callback()
        if(result instanceof Promise) {
          await result
        }

        // Everything went well, reset the retries and delay
        this._goExponentialBackoff(initial, { ...initial }, callback)
      } catch(e) {
        // Something went wrong, try again after the delay
        if(current.retries > 0) {
          this._goExponentialBackoff(initial, { retries: current.retries - 1, delay: current.delay * 2 }, callback)
        } else {
          // We've reached the maximum number of retries, rethrow the error
          throw e
        }
      }
    }, current.delay)
  }

  stop(){
    if(this.exponentialBackoffRetries) {
      clearTimeout(this._ref)
    } else {
      clearInterval(this._ref)
    }
  }
}
