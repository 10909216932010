import { IsHTMLElement } from '../features/dom_utils'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import FootnoteDialog from '../features/dialogs/FootnoteDialog.vue'

export interface FootnotesController
  extends ExposeStimulus<typeof FootnotesController> {}
export class FootnotesController extends StimulusControllerBase {
  static targets = ['editor'] as const

  connect(): void {
    this.initializeFootnotesListeners()
  }

  initializeFootnotesListeners() {
    this.editorTarget.addEventListener('click', (e) => {
      if (!IsHTMLElement(e.target)) return
      const isTargetFootnote = e.target.tagName == 'AVV-FOOTNOTE'
      if (isTargetFootnote) this.onFootnoteClick(e.target)
    })
  }

  onFootnoteClick(footnoteElement: HTMLElement) {
    const footnoteHTML = this.getFootnoteHTML(footnoteElement)
    const footnoteCount = this.getFootnoteCount(footnoteElement)
    if (!footnoteHTML || !footnoteCount) return
    this.openFootnoteModal(footnoteHTML, footnoteCount)
  }

  getFootnoteHTML(footnote: HTMLElement): string | null {
    const id = footnote.getAttribute('data-id')
    if(!id) return footnote.getAttribute('data-value')
    const footnoteElement = document.querySelector(
      `avv-footnotes avv-footnote[data-id="${id}"]`
    )
    if (!footnoteElement) return null
    return footnoteElement.innerHTML
  }

  getFootnoteCount(footnote: HTMLElement): number | null {
    if (!footnote) return null
    return parseInt(footnote.textContent || '0')
  }

  openFootnoteModal(footnoteHTML: string, footnoteCount: number) {
    // TODO(oskar): ensure sanitization of footnoteHTML
    // localize footnote count
    avv_dialog({
      vueDialog: FootnoteDialog,
      props: { html: footnoteHTML, title: `Footnote ${footnoteCount}` },
      callback: () => {}
    })
  }
}

export default FootnotesController
