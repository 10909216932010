import consumer from '../../channels/consumer'
import { stopAndPrevent } from '../../features/_abstract/utils/event'
import {
  type ExposeStimulus,
  StimulusControllerBase
} from './../base_controller'

export interface DatasheetImportController
  extends ExposeStimulus<typeof DatasheetImportController> {}
export class DatasheetImportController extends StimulusControllerBase {
  static values = {
    id: String
  } as const

  static targets = ['progressText'] as const

  // Reload page if no update is received within 5 seconds of loading
  #timeout?: NodeJS.Timeout

  connect() {
    if (this.hasProgressTextTarget) {
      this.#timeout = setTimeout(() => window.location.reload(), 2000)
    }

    consumer.subscriptions.create(
      { channel: 'DatasheetImportChannel', id: this.idValue },
      {
        received: (response: {
          type: 'progress'
          data: { index: number; total: number }
        }) => {
          if (this.#timeout) {
            this.#timeout = void clearTimeout(this.#timeout)
          }

          switch (response.type) {
            case 'progress': {
              this.#progress(response.data.index, response.data.total)
              break
            }
          }
        }
      }
    )
  }

  submit(event: Event) {
    ;(event.target as HTMLInputElement | null)?.form?.submit()
  }

  return(event: Event) {
    stopAndPrevent(event)

    window.avv_dialog({
      confirmTitle: window.localizeText(
        'datasheets.datasheet_import.return.title'
      ),
      confirmMessage: window.localizeText(
        'datasheets.datasheet_import.return.message'
      ),
      okButtonText: window.localizeText(
        'datasheets.datasheet_import.return.ok'
      ),
      confirmCallback(value) {
        if (value) {
          window.location.pathname = `${window.location.pathname.split('/imports/')[0]}/records`
        }
      }
    })
  }

  #progress(index: number, total: number) {
    this.progressTextTarget.innerText = window.localizeText(
      'datasheets.datasheet_import.progress',
      { index, total }
    )

    if (index === total) {
      window.location.reload()
    }
  }
}

export default DatasheetImportController
