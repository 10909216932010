<template>
  <div class="toggle-wrapper">
    <span class="avv-toggle-label" v-text="props.title" />
    <button type="button" class="avv-button avv-toggle" :class="{ active: props.active }" @click="emit('click')" />
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  active: boolean
  title: string
}>()

const emit = defineEmits(['click'])
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'AToggle' })
</script>
