import LinkUtils from '../features/external_links/index'
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface ExternalLinksController extends ExposeStimulus<typeof ExternalLinksController> {}
export class ExternalLinksController extends StimulusControllerBase {
  connect() {
    window.addEventListener('click', (event) => {
      if (!event.target || event.ctrlKey || event.metaKey) return
      let target = event.target as HTMLElement
      if (!LinkUtils.isLinkTag(target))
        target = target.closest('a') as HTMLAnchorElement
      if (LinkUtils.isLinkTag(target) && LinkUtils.hasLinkHref(target)) 
        LinkUtils.openLink(target.href, event, LinkUtils.openLinksInNewTab(target))
    })
  }

  goToExternalLink(e: PointerEvent) {
    const urlDisplay = document.querySelector('#external-link-display')
    let link
    if (urlDisplay) link = urlDisplay.textContent
    if (typeof link == 'string') window.open(link, '_blank')
    this.close(e)
  }
}

export default ExternalLinksController
