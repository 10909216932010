import { Controller } from 'stimulus'
import katex from 'katex'

export default class extends Controller {
  connect () {
    for (const avvFormula of this.element.querySelectorAll('avv-formula')) {
      avvFormula.replaceChildren();

      katex.render(
        avvFormula.getAttribute('data-value'),
        avvFormula,
        {
          throwOnError: false,
          errorColor: 'red'
        }
      )
    }
  }
}
