import SynchronizationsDialog from './SynchronizationsDialog.vue'

import { Config } from '@js-from-routes/client'
import IntegrationsApi from '@api/IntegrationsApi'

// Disable auto conversion from snake_case to camelCase
const noop = (val: unknown) => val
Config.deserializeData = noop
Config.serializeData = noop

export type IntegrationStore = {
  name: string
  resources: {
    [key: string]: {
      icon: string
      file: boolean
      folder: boolean
      search: boolean
    }
  }
}

export type IntegrationStores = {
  [key: string]: IntegrationStore
}

export async function showSynchronizationDialog(
  integration: string,
  scope: string,
  ids: string[]
) {
  window.integrationsStore ||= await IntegrationsApi.store<IntegrationStores>()

  window.avv_dialog({
    vueDialog: SynchronizationsDialog,
    props: {
      integration,
      scope,
      ids,
      store: window.integrationsStore
    },
    callback: (reload: boolean) => {
      if (reload) window.location.reload()
    }
  })
}

declare global {
  const integrationsStore: IntegrationStores

  interface Window {
    integrationsStore: IntegrationStores
  }
}
