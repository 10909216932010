import { Controller } from 'stimulus'

import { showSynchronizationDialog } from '../features/integrations'
import { stopAndPrevent } from '../features/_abstract/utils/event'

import SynchronizationsApi from '@api/Integrations/SynchronizationsApi'

export default class SynchronizationsController extends Controller {
  static values = {
    integration: String,
    scope: String,
    id: String
  }

  declare readonly integrationValue: string
  declare readonly scopeValue: string
  declare readonly idValue: string

  async manage(event: PointerEvent) {
    stopAndPrevent(event)

    try {
      const scope = this.scopeValue
      const id = this.idValue

      const response = await SynchronizationsApi.manage<
        { html: string } | { error: string }
      >({ scope, id })

      if ('error' in response) {
        throw new Error(response.error ?? 'unknown error')
      } else {
        document
          .getElementById('synchronizations-manage-modal')
          ?.parentElement?.remove()
        document.body.insertAdjacentHTML('beforeend', response.html)

        const modal = document.getElementById('synchronizations-manage-modal')
        if (modal) modal.style.display = 'flex'
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        window.avv_toast({
          message: e.message,
          type: 'error',
          icon: 'error'
        })
      }
    }
  }

  #queryDocuments() {
    const documents = Array.from(
      document.querySelectorAll<HTMLInputElement>(
        `.document-ids input[name="documents[]"][data-integrations]:checked`
      )
    ).map(
      (element) =>
        [element.dataset.integrations, element.value] as [string, string]
    )
    const filteredDocumentIds = (type: 'create' | 'update') =>
      documents
        .filter(([attribute]) =>
          attribute.includes(`${type}-${this.integrationValue}`)
        )
        .map(([, value]) => value)

    return {
      documentsAll: documents.map(([, value]) => value),
      documentsCreate: filteredDocumentIds('create'),
      documentsUpdate: filteredDocumentIds('update')
    }
  }

  bulkDocuments(event: PointerEvent) {
    stopAndPrevent(event)

    const { documentsAll, documentsCreate, documentsUpdate } =
      this.#queryDocuments()
    const documentsAllowedCount =
      documentsCreate.length + documentsUpdate.length

    if (documentsAll.length === 0) {
      window.avv_toast({
        message: window.localizeText('integrations.topbar.error_manage_empty')
      })
    } else if (documentsAll.length === documentsAllowedCount) {
      void showSynchronizationDialog(
        this.integrationValue,
        'Document',
        documentsAll
      )
    } else {
      window.avv_toast({
        message: window.localizeText(
          'integrations.topbar.error_manage_mismatch'
        )
      })
    }
  }

  show(event: PointerEvent) {
    stopAndPrevent(event)

    void showSynchronizationDialog(this.integrationValue, this.scopeValue, [
      this.idValue
    ])
  }
}
