import { Controller } from 'stimulus'

export default class SelectableContentController extends Controller {
  static targets = [ "template", "placeholder", "select"]

  connect() {
    if (this.selectTarget.value !== '') {
      const template = this.templateTargets.find(element => element.dataset.id === this.selectTarget.value);

      if(template !== undefined) {
        this.placeholderTarget.innerHTML = template.innerHTML
      }
    }
  }

  show(event) {
    let content = ""
    if (event.target.value !== '') {
      const template = this.templateTargets.find(element => element.dataset.id === event.target.value);

      if(template !== undefined) {
        content = template.innerHTML
      }
    }
    this.placeholderTarget.innerHTML = content
  }
}
