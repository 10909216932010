<template>
  <GlobalDialog
    class="square bg-white !p-4 gap-4 !rounded-md flex min-w-[450px] flex-col dialog !max-h-[90vh]"
  >
    <h1 v-text="localize('title')" />
    <VueDraggable
      v-model="displayedKeys"
      item-key="key"
      handle="i.handle"
      class="flex flex-col text-sm gap-1"
    >
      <template #item="{ element, index }">
        <div class="flex items-center gap-2">
          <i
            class="material-icons-outlined cursor-move handle text-sm"
            aria-hidden="true"
            >drag_indicator</i
          >
          <div
            class="flex flex-row px-2 justify-between items-center h-8 border rounded-sm w-full"
          >
            <span v-text="headers[element].text" />
            <i
              class="material-icons-outlined cursor-pointer"
              aria-hidden="true"
              @click="hide(index)"
              >visibility_off</i
            >
          </div>
        </div>
      </template>
    </VueDraggable>
    <template v-if="unusedKeys.length > 0">
      <h2 v-text="localize('hidden')" />
      <div class="flex flex-col text-sm gap-1">
        <div v-for="key in unusedKeys" class="flex items-center gap-2">
          <i
            class="material-icons-outlined text-sm text-transparent"
            aria-hidden="true"
            >drag_indicator</i
          >
          <div
            class="flex flex-row px-2 justify-between items-center h-8 border rounded-sm w-full"
          >
            <span v-text="headers[key].text" />
            <i
              class="material-icons-outlined cursor-pointer"
              aria-hidden="true"
              @click="show(key)"
              >visibility</i
            >
          </div>
        </div>
      </div>
    </template>
    <div class="grid grid-cols-2 gap-2 w-full">
      <button
        class="avv-button w-full secondary"
        @click="emit('callback', false)"
        v-text="localize('cancel')"
      />
      <button
        class="avv-button w-full"
        @click="save"
        v-text="localize('save')"
      />
    </div>
  </GlobalDialog>
</template>

<script lang="ts" setup>
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import VueDraggable from '@libs/zhyswan-vuedraggable/vuedraggable'

import { computed, ref } from 'vue'
import { docuSignBatches } from '@api/all'

const props = defineProps<{
  display?: string[]
  batch_id: number
  headers: Record<string, { text: string; key: string; default: boolean }>
}>()

const emit = defineEmits<{
  (e: string, success: boolean): void
}>()

const hide = (index: number) => {
  displayedKeys.value.splice(index, 1)
}

const show = (key: string) => {
  displayedKeys.value.push(key)
}

const headers = computed(() => props.headers)

const displayedKeys = ref(
  props.display ??
    Object.entries(headers.value)
      .filter(([, header]) => header.default)
      .map(([, { key }]) => key)
)

const unusedKeys = computed(() =>
  Object.values(headers.value)
    .filter((header) => !displayedKeys.value.includes(header.key))
    .map(({ key }) => key)
)

const save = async () => {
  await docuSignBatches.update({
    data: { id: props.batch_id, display: displayedKeys.value }
  })

  emit('callback', true)
}

const localize = (key: string) =>
  window.localizeText(`docu_sign.dashboard.config.${key}`)
</script>
